import React, { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    Container,
    CssBaseline,
    Paper,
    Snackbar,
    Typography,
    Chip,
    Toolbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    IconButton,
    Switch,
    FormControlLabel,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@mui/material';
import { BASE_URL } from '../../Constants.js';
import { getToken, getUserId, getRoleId, getClientId } from '../../auth/auth.js';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import { styled, useTheme } from '@mui/material/styles';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { useNavigate } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';

const PlaceholderBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.05)',
    borderRadius: theme.shape.borderRadius,
    aspectRatio: '16/9',
    overflow: 'hidden'
}));

function LMSCourseList() {
    const [courses, setCourses] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editingCourse, setEditingCourse] = useState(null);
    const coursesPerPage = 5;
    
    // Edit form states
    const [courseName, setCourseName] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [isPublic, setIsPublic] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [clients, setClients] = useState([]);
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [sendEmailNotification, setSendEmailNotification] = useState(false);
    const [courseType, setCourseType] = useState('');
    const [oracleInstructions, setOracleInstructions] = useState('');
    const [existingDocs, setExistingDocs] = useState([]);
    const [newDocs, setNewDocs] = useState([]);
    
    // Add this new state
    const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);
    const [tempInstructions, setTempInstructions] = useState('');

    const isSuperAdmin = getRoleId() === 1;
    const currentClientId = getClientId();

    const theme = useTheme();
    const navigate = useNavigate();

    // Fetch courses
    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch courses');
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setError('Failed to fetch courses');
        }
    };

    // Handle edit dialog open
    const handleEdit = (course) => {
        setEditingCourse(course);
        setCourseName(course.course_name);
        setCourseDescription(course.course_description || '');
        setIsPublic(course.is_public);
        setSelectedRoles(Array.isArray(course.access_roles) ? course.access_roles : []);
        setSelectedClients(Array.isArray(course.access_clients) ? course.access_clients : []);
        setSelectedUsers(Array.isArray(course.assigned_users) ? 
            course.assigned_users.map(user => user.user_id) : []);
        setSendEmailNotification(false);
        setCourseType(course.course_type || 'regular');
        setOracleInstructions(course.oracle_instructions || '');
        
        // Initialize existing docs with keep property
        setExistingDocs(course.course_documents ? course.course_documents.map(doc => ({
            ...doc,
            keep: true // Initialize all docs as kept
        })) : []);
        setNewDocs([]);
        
        if (course.image_path) {
            setImagePreview(`${BASE_URL}/uploads${course.image_path}`);
        } else {
            setImagePreview(null);
        }
        
        setEditDialogOpen(true);
        fetchAvailableUsers();
    };

    // Handle course update
    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append('course_name', courseName.trim());
            formData.append('course_description', courseDescription.trim());
            formData.append('is_public', isPublic ? '1' : '0');
            formData.append('assigned_users', JSON.stringify(selectedUsers));
            formData.append('send_email', sendEmailNotification ? '1' : '0');
            formData.append('access_control', JSON.stringify({
                roles: selectedRoles || [],
                clients: isSuperAdmin ? (selectedClients || []) : [currentClientId]
            }));
            formData.append('course_type', courseType);
            formData.append('oracle_instructions', courseType === 'oracle' ? oracleInstructions : '');
            formData.append('existing_docs', JSON.stringify(existingDocs));

            // Append new image if selected
            if (newImage) {
                formData.append('course_image', newImage);
            }

            // Append new documents
            newDocs.forEach((doc, index) => {
                formData.append(`courseDoc_${index}`, doc);
            });

            const response = await fetch(`${BASE_URL}/api/courses/${editingCourse.id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update course');
            }

            setSuccessMessage('Course updated successfully!');
            setSuccess(true);
            setEditDialogOpen(false);
            fetchCourses();
            
            // Reset states
            setNewImage(null);
            setImagePreview(null);
            setNewDocs([]);
            setExistingDocs([]);
        } catch (error) {
            console.error('Error updating course:', error);
            setError(error.message || 'Failed to update course');
        } finally {
            setIsLoading(false);
        }
    };

    // Handle delete
    const handleDelete = async (courseId) => {
        if (!window.confirm('Are you sure you want to delete this course?')) return;

        try {
            const response = await fetch(`${BASE_URL}/api/courses/${courseId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete course');
            
            setSuccessMessage('Course deleted successfully!');
            setSuccess(true);
            fetchCourses();
        } catch (error) {
            setError('Failed to delete course');
        }
    };

    // Fetch available users for private courses
    const fetchAvailableUsers = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/readadmins`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch users');
            }
            const data = await response.json();
            setAvailableUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch available users');
        }
    };

    // Add image handling functions
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleRemoveImage = () => {
        setNewImage(null);
        setImagePreview(null);
    };

    // Add this function to handle video navigation
    const handleAddVideo = (courseId) => {
        navigate('/lmsaddvideos', { 
            state: { selectedCourseId: courseId }
        });
    };

    // Add this function to handle navigation to manage videos
    const handleManageVideos = (courseId) => {
        navigate('/manage-videos', { 
            state: { selectedCourseId: courseId }
        });
    };

    // Add document handling functions
    const handleDocumentAdd = (event) => {
        const files = Array.from(event.target.files);
        setNewDocs(prev => [...prev, ...files]);
    };

    const handleRemoveNewDoc = (index) => {
        setNewDocs(prev => prev.filter((_, i) => i !== index));
    };

    const handleRemoveExistingDoc = (docId) => {
        setExistingDocs(prev => prev.map(doc => 
            doc.id === docId ? { ...doc, keep: false } : doc
        ));
    };

    const handleRestoreDoc = (docId) => {
        setExistingDocs(prev => prev.map(doc => 
            doc.id === docId ? { ...doc, keep: true } : doc
        ));
    };

    // Add these new handlers
    const handleOpenInstructionsDialog = () => {
        setTempInstructions(oracleInstructions);
        setOpenInstructionsDialog(true);
    };

    const handleCloseInstructionsDialog = () => {
        setOpenInstructionsDialog(false);
    };

    const handleSaveInstructions = () => {
        setOracleInstructions(tempInstructions);
        handleCloseInstructionsDialog();
    };

    useEffect(() => {
        fetchCourses();
        if (isSuperAdmin) {
            // Fetch clients for super admin
            fetch(`${BASE_URL}/api/readclients`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setClients(data);
                }
            })
            .catch(error => console.error('Error fetching clients:', error));
        }
    }, []);

    const indexOfLastCourse = page * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);
    const renderRoles = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                    <Chip 
                        key={value} 
                        label={value === 1 ? 'Super Admin' : value === 2 ? 'Admin' : 'User'} 
                    />
                ))}
            </Box>
        );
    };

    const renderClients = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((clientId) => (
                    <Chip 
                        key={clientId} 
                        label={clients.find(c => c.client_id === clientId)?.client_name || clientId} 
                    />
                ))}
            </Box>
        );
    };

    const renderSelectedUsers = (selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((userId) => {
                const user = availableUsers.find(u => u.user_id === userId);
                return user ? (
                    <Chip
                        key={userId}
                        label={`${user.first_name} ${user.last_name} (${user.email})`}
                        size="small"
                    />
                ) : null;
            })}
        </Box>
    );

    return (
        <Box sx={{ 
            backgroundColor: 'background.default', 
            flexGrow: 1, 
            minHeight: '100vh', 
            overflow: 'auto',
            pt: { xs: '64px', md: '24px' }, // Add responsive top padding
        }}>
            <CssBaseline />
            <Container sx={{ 
                py: 3, // Consistent vertical padding
                px: { xs: 2, sm: 3 }, // Consistent horizontal padding
                maxWidth: '1000px !important' 
            }}>
                <Typography variant="h4" gutterBottom>
                    Course Management
                </Typography>

                {/* Course List */}
                <Paper sx={{ p: 4 }}>
                    {currentCourses.map((course) => (
                        <Box key={course.id} sx={{ 
                            mb: 2, 
                            p: { xs: 1.5, sm: 2 }, // Responsive padding
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile, row on tablet+
                            gap: { xs: 1.5, sm: 2 },
                            backgroundColor: 'background.paper',
                        }}>
                            {/* Course Image Container */}
                            <Box sx={{ 
                                flexShrink: 0,
                                width: { xs: '100%', sm: 240 },
                                aspectRatio: '16/9',
                                borderRadius: 1,
                                overflow: 'hidden',
                                backgroundColor: theme.palette.mode === 'dark' 
                                    ? 'rgba(255, 255, 255, 0.1)' 
                                    : 'rgba(0, 0, 0, 0.05)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {course.image_path ? (
                                    <Box
                                        component="img"
                                        src={`${BASE_URL}/uploads${course.image_path}`}
                                        alt={course.course_name}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                            },
                                        }}
                                        onError={(e) => {
                                            e.target.style.display = 'none';
                                            e.target.parentElement.appendChild(
                                                document.createElement('div')
                                            ).innerHTML = `
                                                <div style="
                                                    width: 100%;
                                                    height: 100%;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    background-color: ${theme.palette.mode === 'dark' 
                                                        ? 'rgba(255, 255, 255, 0.1)' 
                                                        : 'rgba(0, 0, 0, 0.05)'};
                                                ">
                                                    <svg style="width: 48px; height: 48px; opacity: 0.7;" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M21,5C19.89,4.65 18.67,4.5 17.5,4.5C15.55,4.5 13.45,4.9 12,6C10.55,4.9 8.45,4.5 6.5,4.5C4.55,4.5 2.45,4.9 1,6V20.65C1,20.9 1.25,21.15 1.5,21.15C1.6,21.15 1.65,21.1 1.75,21.1C3.1,20.45 5.05,20 6.5,20C8.45,20 10.55,20.4 12,21.5C13.35,20.65 15.8,20 17.5,20C19.15,20 20.85,20.3 22.25,21.05C22.35,21.1 22.4,21.1 22.5,21.1C22.75,21.1 23,20.85 23,20.6V6C22.4,5.55 21.75,5.25 21,5M21,18.5C19.9,18.15 18.7,18 17.5,18C15.8,18 13.35,18.65 12,19.5V8C13.35,7.15 15.8,6.5 17.5,6.5C18.7,6.5 19.9,6.65 21,7V18.5Z" />
                                                    </svg>
                                                </div>`;
                                        }}
                                    />
                                ) : (
                                    <AutoStoriesIcon 
                                        sx={{ 
                                            width: 48,
                                            height: 48,
                                            opacity: 0.7,
                                            color: theme.palette.mode === 'dark' 
                                                ? 'rgba(255, 255, 255, 0.9)'
                                                : 'rgba(0, 0, 0, 0.6)'
                                        }} 
                                    />
                                )}
                            </Box>

                            {/* Course Details */}
                            <Box sx={{ 
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 0
                            }}>
                                <Box sx={{ 
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: { xs: 'flex-start', sm: 'center' },
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: { xs: 1, sm: 0 }
                                }}>
                                    <Typography 
                                        variant="h6" 
                                        sx={{
                                            fontSize: { xs: '1.1rem', sm: '1.25rem' },
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {course.course_name}
                                    </Typography>

                                    {/* Action Buttons */}
                                    <Box sx={{ 
                                        display: 'flex',
                                        gap: 1,
                                        width: { xs: '100%', sm: 'auto' },
                                        justifyContent: { xs: 'flex-start', sm: 'flex-end' }
                                    }}>
                                        <Tooltip title="Edit Course">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleEdit(course)}
                                                sx={{ p: { xs: 1, sm: 1.2 } }} // Larger touch target on mobile
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Add Videos" arrow placement="bottom">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleAddVideo(course.id)}
                                                sx={{ 
                                                    color: 'success.main',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(76, 175, 80, 0.08)'
                                                    }
                                                }}
                                            >
                                                <VideoCallIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Manage Videos" arrow placement="bottom">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleManageVideos(course.id)}
                                                sx={{ 
                                                    color: 'info.main',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(33, 150, 243, 0.08)'
                                                    }
                                                }}
                                            >
                                                <VideoLibraryIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete Course" arrow placement="bottom">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleDelete(course.id)}
                                                sx={{ 
                                                    color: 'error.main',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(211, 47, 47, 0.08)'
                                                    }
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>

                                <Typography 
                                    variant="body2" 
                                    color="text.secondary"
                                    sx={{ 
                                        mt: 1,
                                        display: '-webkit-box',
                                        WebkitLineClamp: { xs: 2, sm: 3 },
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        fontSize: { xs: '0.875rem', sm: '0.875rem' }
                                    }}
                                >
                                    {course.course_description}
                                </Typography>

                                {/* Chips */}
                                <Box sx={{ 
                                    mt: { xs: 1, sm: 1.5 },
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5
                                }}>
                                    <Chip 
                                        label={course.is_public ? "Public" : "Private"} 
                                        size="small"
                                        sx={{ fontSize: { xs: '0.75rem', sm: '0.8125rem' } }}
                                    />
                                    {course.course_type === 'oracle' && (
                                        <Chip
                                            label="Oracle"
                                            size="small"
                                            sx={{ 
                                                fontSize: { xs: '0.75rem', sm: '0.8125rem' },
                                                backgroundColor: 'primary.main',
                                                color: 'white'
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                    
                    {/* Pagination */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Pagination
                            count={Math.ceil(courses.length / coursesPerPage)}
                            page={page}
                            onChange={(event, value) => setPage(value)}
                            color="primary"
                        />
                    </Box>
                </Paper>

                {/* Edit Dialog */}
                <Dialog
                    open={editDialogOpen}
                    onClose={() => setEditDialogOpen(false)}
                    maxWidth="md"
                    fullWidth
                    sx={{
                        '& .MuiDialog-paper': {
                            margin: { xs: '16px', sm: '32px' },
                            width: { xs: 'calc(100% - 32px)', sm: 'auto' },
                            maxHeight: { xs: 'calc(100% - 32px)', sm: '90vh' },
                            position: { xs: 'relative', sm: 'fixed' },
                            top: { sm: '50%' },
                            left: { sm: '58%' },
                            transform: { sm: 'translate(-50%, -50%)' }
                        },
                        zIndex: 10000
                    }}
                >
                    <DialogTitle>Edit Course</DialogTitle>
                    <DialogContent
                        sx={{
                            overflowY: 'auto',
                            maxHeight: 'calc(100vh - 200px)', // Adjust height as needed
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: 'rgba(255, 255, 255, 0.3)',
                            },
                        }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Course Name"
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                        />
                        
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            rows={4}
                            label="Course Description"
                            value={courseDescription}
                            onChange={(e) => setCourseDescription(e.target.value)}
                        />

                        {/* Image upload section */}
                        <Box sx={{ mt: 2 }}>
                            <input
                                type="file"
                                id="image-upload"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />
                            <label htmlFor="image-upload">
                                <Button
                                    variant="outlined"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Upload Course Image
                                </Button>
                            </label>
                            {imagePreview && (
                                <Box sx={{ mt: 2, position: 'relative', width: 'fit-content' }}>
                                    <img
                                        src={imagePreview}
                                        alt="Course preview"
                                        style={{ maxWidth: '200px', maxHeight: '200px' }}
                                    />
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: -12,
                                            right: -12,
                                            backgroundColor: 'background.paper'
                                        }}
                                        size="small"
                                        onClick={handleRemoveImage}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Course Access Type</InputLabel>
                            <Select
                                value={isPublic}
                                onChange={(e) => {
                                    setIsPublic(e.target.value);
                                    if (e.target.value) {
                                        setSelectedUsers([]); // Clear selected users when switching to public
                                    }
                                }}
                                label="Course Access Type"
                            >
                                <MenuItem value={true}>Public</MenuItem>
                                <MenuItem value={false}>Private</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Assign Students</InputLabel>
                            <Select
                                multiple
                                value={selectedUsers}
                                onChange={(e) => setSelectedUsers(e.target.value)}
                                input={<OutlinedInput label="Assign Students" />}
                                renderValue={renderSelectedUsers}
                            >
                                {availableUsers.map((user) => (
                                    <MenuItem key={user.user_id} value={user.user_id}>
                                        {`${user.first_name} ${user.last_name} (${user.email})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {courseType === 'oracle' && (
                            <>
                                <Box sx={{ position: 'relative' }}>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        multiline
                                        rows={6}
                                        label="Oracle Instructions"
                                        value={oracleInstructions}
                                        onChange={(e) => setOracleInstructions(e.target.value)}
                                    />
                                    <IconButton
                                        onClick={handleOpenInstructionsDialog}
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 28,
                                            bgcolor: 'background.paper',
                                            boxShadow: 1,
                                            padding: '4px',
                                            '&:hover': {
                                                bgcolor: 'action.hover',
                                            },
                                        }}
                                    >
                                        <OpenInFullIcon fontSize="small" />
                                    </IconButton>
                                </Box>

                                {/* Instructions Dialog */}
                                <Dialog
                                    open={openInstructionsDialog}
                                    onClose={handleCloseInstructionsDialog}
                                    maxWidth="md"
                                    fullWidth
                                    PaperProps={{
                                        sx: {
                                            height: '80vh',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            position: 'fixed',
                                            left: '58%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            margin: '0 !important',
                                            width: '100%',
                                            maxWidth: '900px'
                                        }
                                    }}
                                    sx={{
                                        '& .MuiDialog-container': {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        },
                                        zIndex: 10000
                                    }}
                                >
                                    <DialogTitle sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        pb: 1
                                    }}>
                                        <Typography variant="h6">
                                            Edit Oracle AI Instructions
                                        </Typography>
                                        <IconButton
                                            onClick={handleCloseInstructionsDialog}
                                            size="small"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', py: 2 }}>
                                        <TextField
                                            autoFocus
                                            multiline
                                            fullWidth
                                            value={tempInstructions}
                                            onChange={(e) => setTempInstructions(e.target.value)}
                                            variant="outlined"
                                            placeholder="Enter your instructions here..."
                                            sx={{ 
                                                flex: 1,
                                                '& .MuiInputBase-root': {
                                                    height: '100%',
                                                },
                                                '& .MuiInputBase-input': {
                                                    height: '100% !important',
                                                }
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions sx={{ p: 2, gap: 1 }}>
                                        <Button onClick={handleCloseInstructionsDialog} variant="outlined">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSaveInstructions} variant="contained">
                                            Save Changes
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                {/* Existing Documents */}
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Course Documents
                                    </Typography>
                                    <List>
                                        {existingDocs.map((doc) => (
                                            <ListItem key={doc.id}>
                                                <ListItemText 
                                                    primary={doc.document_name}
                                                    secondary={doc.keep === false ? 'Marked for deletion' : ''}
                                                    sx={{
                                                        textDecoration: doc.keep === false ? 'line-through' : 'none',
                                                        color: doc.keep === false ? 'text.secondary' : 'text.primary'
                                                    }}
                                                />
                                                <ListItemSecondaryAction>
                                                    {doc.keep === false ? (
                                                        <IconButton edge="end" onClick={() => handleRestoreDoc(doc.id)}>
                                                            <UndoIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton edge="end" onClick={() => handleRemoveExistingDoc(doc.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>

                                {/* New Documents */}
                                <Box sx={{ mt: 2 }}>
                                    <input
                                        type="file"
                                        id="document-upload"
                                        multiple
                                        accept=".pdf,.doc,.docx,.txt,.csv"
                                        style={{ display: 'none' }}
                                        onChange={handleDocumentAdd}
                                    />
                                    <label htmlFor="document-upload">
                                        <Button
                                            variant="outlined"
                                            component="span"
                                            startIcon={<UploadFileIcon />}
                                        >
                                            Add Documents
                                        </Button>
                                    </label>

                                    <List>
                                        {newDocs.map((doc, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={doc.name} />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={() => handleRemoveNewDoc(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </>
                        )}

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendEmailNotification}
                                    onChange={(e) => setSendEmailNotification(e.target.checked)}
                                    name="sendEmailNotification"
                                    color="primary"
                                />
                            }
                            label="Send email notification to users"
                            sx={{ mt: 2, mb: 2, display: 'block' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
                        <Button onClick={handleUpdate} variant="contained" disabled={isLoading}>
                            {isLoading ? 'Updating...' : 'Update'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbars */}
                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                    <Alert onClose={() => setSuccess(false)} severity="success">
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default LMSCourseList; 