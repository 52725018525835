import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Typography, Avatar, TextField, Button, IconButton,
  Menu, MenuItem, Divider
} from '@mui/material';
import { format } from 'date-fns';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth';

function Comments({ postId, currentUser, onCommentUpdate }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [replyTo, setReplyTo] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const commentInputRef = useRef(null);

  useEffect(() => {
    fetchComments();
  }, [postId]);

  useEffect(() => {
    if (commentInputRef.current) {
      commentInputRef.current.focus();
    }
  }, []);

  const fetchComments = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/feed/posts/${postId}/comments`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        // Organize comments into threads with validation
        const threaded = data.reduce((acc, comment) => {
          if (!comment || !comment.content) return acc;  // Skip invalid comments

          if (!comment.parent_comment_id) {
            if (!acc[comment.comment_id]) {
              acc[comment.comment_id] = { ...comment, replies: [] };
            } else {
              acc[comment.comment_id] = { ...acc[comment.comment_id], ...comment };
            }
          } else {
            if (!acc[comment.parent_comment_id]) {
              acc[comment.parent_comment_id] = { replies: [comment] };
            } else {
              acc[comment.parent_comment_id].replies.push(comment);
            }
          }
          return acc;
        }, {});
        
        // Filter out any remaining invalid comments
        const validComments = Object.values(threaded).filter(
          comment => comment && comment.content
        );
        
        setComments(validComments);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;
    
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/feed/comments`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          post_id: postId,
          parent_comment_id: replyTo?.comment_id || null,
          content: newComment
        })
      });

      if (response.ok) {
        setNewComment('');
        setReplyTo(null);
        await fetchComments();
        // Notify parent component about the comment update
        onCommentUpdate && onCommentUpdate(postId);
      }
    } catch (error) {
      console.error('Error posting comment:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditComment = async (commentId, content) => {
    try {
      const response = await fetch(`${BASE_URL}/api/feed/comments/${commentId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ content })
      });

      if (response.ok) {
        setEditingComment(null);
        await fetchComments();
      }
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/feed/comments/${commentId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });

      if (response.ok) {
        await fetchComments();
        // Notify parent component about the comment update
        onCommentUpdate && onCommentUpdate(postId);
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
    handleCloseMenu();
  };

  const handleOpenMenu = (event, comment) => {
    setAnchorEl(event.currentTarget);
    setSelectedComment(comment);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedComment(null);
  };

  const renderComment = (comment, isReply = false) => {
    if (!comment) return null;

    const formatDate = (dateString) => {
      if (!dateString) return '';
      try {
        return format(new Date(dateString), 'MMM dd, yyyy');
      } catch (error) {
        console.error('Invalid date:', dateString);
        return '';
      }
    };

    return (
      <Box
        key={comment.comment_id}
        sx={{
          mt: isReply ? 2 : 3,
          ml: isReply ? 6 : 0
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Avatar
            sx={{
              width: 32,
              height: 32,
              bgcolor: '#7B4FFF'
            }}
          >
            {comment.first_name?.[0]}
          </Avatar>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between'
            }}>
              <Box>
                <Typography sx={{ 
                  fontWeight: 600,
                  fontSize: '0.9rem',
                  color: '#fff'
                }}>
                  {comment.first_name} {comment.last_name}
                </Typography>
                <Typography sx={{ 
                  fontSize: '0.8rem',
                  color: '#999'
                }}>
                  {formatDate(comment.date_created)}
                  {comment.date_edited && ' (edited)'}
                </Typography>
              </Box>
              {comment.user_id === currentUser.user_id && (
                <IconButton
                  size="small"
                  onClick={(e) => handleOpenMenu(e, comment)}
                  sx={{ color: '#999' }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>

            {editingComment?.comment_id === comment.comment_id ? (
              <Box component="form" onSubmit={(e) => {
                e.preventDefault();
                handleEditComment(comment.comment_id, editingComment.content);
              }}>
                <TextField
                  fullWidth
                  multiline
                  value={editingComment.content}
                  onChange={(e) => setEditingComment({
                    ...editingComment,
                    content: e.target.value
                  })}
                  sx={{
                    mt: 1,
                    '& .MuiOutlinedInput-root': {
                      bgcolor: '#333',
                      '& fieldset': { borderColor: 'rgba(255,255,255,0.1)' }
                    }
                  }}
                />
                <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{ bgcolor: '#7B4FFF' }}
                  >
                    Save
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setEditingComment(null)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography sx={{ 
                mt: 1,
                color: 'rgba(255,255,255,0.9)',
                fontSize: '0.9rem',
                lineHeight: 1.5
              }}>
                {comment.content}
              </Typography>
            )}

            {!isReply && (
              <Button
                size="small"
                startIcon={<ReplyIcon />}
                onClick={() => setReplyTo(comment)}
                sx={{ 
                  mt: 1,
                  color: '#999',
                  textTransform: 'none'
                }}
              >
                Reply
              </Button>
            )}
          </Box>
        </Box>

        {/* Render replies */}
        {comment.replies?.map(reply => renderComment(reply, true))}

        {/* Reply input */}
        {replyTo?.comment_id === comment.comment_id && (
          <Box sx={{ ml: 6, mt: 2 }}>
            <Box component="form" onSubmit={handleSubmitComment}>
              <TextField
                fullWidth
                multiline
                placeholder="Write a reply..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: '#333',
                    '& fieldset': { borderColor: 'rgba(255,255,255,0.1)' }
                  }
                }}
              />
              <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  sx={{ bgcolor: '#7B4FFF' }}
                >
                  Reply
                </Button>
                <Button
                  size="small"
                  onClick={() => setReplyTo(null)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      {/* Comment input */}
      <Box component="form" onSubmit={handleSubmitComment}>
        <TextField
          inputRef={commentInputRef}
          fullWidth
          multiline
          placeholder="Write a comment..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: '#333',
              '& fieldset': { borderColor: 'rgba(255,255,255,0.1)' }
            }
          }}
        />
        {newComment.trim() && (
          <Button
            size="small"
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{ 
              mt: 1,
              bgcolor: '#7B4FFF'
            }}
          >
            Comment
          </Button>
        )}
      </Box>

      {/* Comments list */}
      <Box sx={{ mt: 3 }}>
        {comments
          .filter(comment => comment && comment.content)
          .map(comment => renderComment(comment))}
      </Box>

      {/* Comment actions menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => {
          if (selectedComment) {
            setEditingComment(selectedComment);
            handleCloseMenu();
          }
        }}>
          <EditIcon sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={() => {
          if (selectedComment?.comment_id) {
            handleDeleteComment(selectedComment.comment_id);
          }
        }}>
          <DeleteIcon sx={{ mr: 1 }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Comments; 