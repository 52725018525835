import React, { createContext, useReducer, useState, useEffect } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Define the initial state of the NavBar and the subheadings
const initialState = {
  openNavBar: true,
  openShipments: false,
  openReceived: false,
  openCut: false,
  openYield: false,
};

// Define a reducer function to handle state changes
const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_NAVBAR':
      return { ...state, openNavBar: !state.openNavBar };
    case 'TOGGLE_SUBHEADING':
      return { ...state, [action.payload]: !state[action.payload] };
    default:
      return state;
  }
};

export const NavBarContext = createContext();

export const NavBarProvider = ({ children, defaultOpen = true }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openNavBar, setOpenNavBar] = useState(!isMobile && defaultOpen);

  useEffect(() => {
    setOpenNavBar(!isMobile && defaultOpen);
  }, [isMobile, defaultOpen]);

  const toggleNavBar = () => setOpenNavBar(prev => !prev);

  return (
    <NavBarContext.Provider value={{ openNavBar, toggleNavBar, isMobile }}>
      {children}
    </NavBarContext.Provider>
  );
};
