import React, { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    IconButton,
    Paper,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    Link,
    CircularProgress,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Step,
    StepLabel,
    Stepper,
    FormControlLabel,
    Switch,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UndoIcon from '@mui/icons-material/Undo';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { BASE_URL } from '../../Constants.js';
import { getToken, getUserId } from '../../auth/auth.js';
import { useLocation } from 'react-router-dom';

function LMSManageVideos() {
    const location = useLocation();
    const [videos, setVideos] = useState([]);
    const [page, setPage] = useState(1);
    const [videosPerPage] = useState(5);
    const [expandedCourse, setExpandedCourse] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [transcript, setTranscript] = useState(null);
    const [videoType, setVideoType] = useState('youtube');
    const [videoFile, setVideoFile] = useState(null);
    const [supportingDocs, setSupportingDocs] = useState([]);
    const [newSupportingDocs, setNewSupportingDocs] = useState([]);
    const [docsToKeep, setDocsToKeep] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [user, setUser] = useState({ user_id: getUserId() });
    const [isLoading, setIsLoading] = useState(false);
    const [sendEmailNotification, setSendEmailNotification] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(location.state?.selectedCourseId || null);
    const [isInitialized, setIsInitialized] = useState(false);

    const fetchVideos = async () => {
        try {
            console.log('Fetching videos for courseId:', selectedCourseId); // Debug log
            const response = await fetch(`${BASE_URL}/api/lmsvideos`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch videos');
            const data = await response.json();
            
            if (selectedCourseId && courses.length > 0) {
                const course = courses.find(c => c.id === selectedCourseId);
                console.log('Found course:', course); // Debug log
                
                if (course) {
                    const filteredVideos = data.filter(video => 
                        video.associated_course === course.course_name
                    );
                    console.log('Filtered videos:', filteredVideos); // Debug log
                    setVideos(filteredVideos);
                    setExpandedCourse(course.course_name);
                }
            } else {
                setVideos(data);
            }
        } catch (error) {
            console.error('Error fetching videos:', error);
            setError('Failed to fetch videos');
        }
    };

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch courses');
            }
            const data = await response.json();
            //console.log('Courses:', data);
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                setIsLoading(true);
                await fetchCourses();
                await fetchVideos();
                setUser({ user_id: getUserId() });
            } catch (error) {
                console.error('Error initializing:', error);
                setError('Failed to load data');
            } finally {
                setIsLoading(false);
                setIsInitialized(true);
            }
        };
        
        init();
    }, [selectedCourseId]);

    const handleDelete = async (videoId) => {
        if (!window.confirm('Are you sure you want to delete this video?')) return;

        setIsDeleting(true);
        try {
            const response = await fetch(`${BASE_URL}/api/lmsvideo/${videoId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete video');
            
            setSuccess('Video deleted successfully!');
            fetchVideos();
        } catch (error) {
            setError(error.message);
        } finally {
            setIsDeleting(false);
        }
    };

    const organizeVideosByCourse = () => {
        const organized = {};
        videos.forEach(video => {
            if (!organized[video.associated_course]) {
                organized[video.associated_course] = [];
            }
            organized[video.associated_course].push(video);
        });
        return organized;
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleAccordionChange = (course) => (event, isExpanded) => {
        setExpandedCourse(isExpanded ? course : null);
        setPage(1); // Reset to first page when changing course
    };

    const handleEdit = (video) => {
        setEditMode(true);
        setSelectedVideo(video);
        
        setTitle(video.title);
        setVideoType(video.url ? 'youtube' : 'mp4');
        if (video.url) {
            setUrl(video.url);
        }
        
        const course = courses.find(c => c.course_name === video.associated_course);
        if (course) {
            setSelectedCourse(course.id);
        }
        
        if (video.transcript_file) {
            setTranscript({ 
                name: video.transcript_file,
                existing: true
            });
        }
        
        if (video.mp4_path) {
            setVideoFile({
                name: video.mp4_path.split('/').pop(),
                existing: true
            });
        }
        
        if (video.supporting_documents?.length > 0) {
            setSupportingDocs(video.supporting_documents);
            setDocsToKeep(video.supporting_documents.map(doc => ({ 
                id: doc.id, 
                keep: true 
            })));
        }

        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        resetForm();
    };

    const resetForm = () => {
        setTitle('');
        setUrl('');
        setVideoFile(null);
        setTranscript(null);
        setSelectedCourse('');
        setEditMode(false);
        setSelectedVideo(null);
        setVideoType('youtube');
        setSupportingDocs([]);
        setNewSupportingDocs([]);
        setDocsToKeep([]);
        setActiveStep(0);
        setSendEmailNotification(false);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'application/pdf' || file.type === 'text/plain')) {
            setTranscript(file);
        } else {
            setError('Please upload a PDF or TXT file');
            event.target.value = null;
        }
    };

    const handleVideoFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'video/mp4') {
            setVideoFile(file);
        } else {
            setError('Please upload an MP4 file');
            event.target.value = null;
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isStepOneValid = () => {
        return selectedCourse && title;
    };

    const isStepTwoValid = () => {
        return (videoType === 'youtube' && url) || 
               (videoType === 'mp4' && (videoFile || (editMode && selectedVideo?.mp4_path)));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (activeStep < 2) {
            handleNext();
            return;
        }

        setIsLoading(true);
        setError('');
        setSuccess('');

        try {
            const selectedCourseData = courses.find(course => course.id === selectedCourse);
            if (!selectedCourseData) {
                throw new Error('Please select a valid course');
            }

            const formData = new FormData();
            formData.append('title', title);
            formData.append('videoType', videoType);
            formData.append('associated_course', selectedCourseData.course_name);
            formData.append('associated_course_id', selectedCourseData.id);
            formData.append('who_created', user.user_id);
            formData.append('date_created', new Date().toISOString().slice(0, 19).replace('T', ' '));
            formData.append('send_email', sendEmailNotification ? '1' : '0');

            if (videoType === 'youtube') {
                formData.append('url', url);
            } else if (videoFile && !videoFile.existing) {
                formData.append('videoFile', videoFile);
            }

            if (transcript && !transcript.existing) {
                formData.append('transcript', transcript);
            }

            if (editMode && docsToKeep.length > 0) {
                formData.append('existingDocs', JSON.stringify(docsToKeep));
            }

            newSupportingDocs.forEach((file, index) => {
                formData.append(`supportingDoc_${index}`, file);
            });

            const endpoint = editMode 
                ? `${BASE_URL}/api/lmsvideo/${selectedVideo.id}`
                : `${BASE_URL}/api/lmsvideo`;

            const response = await fetch(endpoint, {
                method: editMode ? 'PUT' : 'POST',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                },
                body: formData
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to update video');
            }

            setSuccess(editMode ? 'Video updated successfully!' : 'Video added successfully!');
            handleCloseModal();
            fetchVideos();
            resetForm();
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const renderStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <>
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="associated-course-label">Associated Course</InputLabel>
                            <Select
                                labelId="associated-course-label"
                                id="associatedCourse"
                                value={selectedCourse}
                                onChange={(e) => setSelectedCourse(e.target.value)}
                                label="Associated Course"
                                required
                            >
                                <MenuItem value="">
                                    <em>Select a course</em>
                                </MenuItem>
                                {courses.map((course) => (
                                    <MenuItem key={course.id} value={course.id}>
                                        {course.course_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Video Title"
                            name="title"
                            autoFocus
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </>
                );
            case 1:
                return (
                    <>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Video Type</InputLabel>
                            <Select
                                value={videoType}
                                onChange={(e) => setVideoType(e.target.value)}
                                label="Video Type"
                            >
                                <MenuItem value="youtube">YouTube URL</MenuItem>
                                <MenuItem value="mp4">MP4 Upload</MenuItem>
                            </Select>
                        </FormControl>

                        {videoType === 'youtube' ? (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="url"
                                label="YouTube URL"
                                name="url"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                helperText="Enter the full YouTube video URL"
                            />
                        ) : (
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<CloudUploadIcon />}
                                    fullWidth
                                >
                                    {videoFile ? videoFile.name : 'Upload MP4 Video'}
                                    <input
                                        type="file"
                                        hidden
                                        accept="video/mp4"
                                        onChange={handleVideoFileChange}
                                    />
                                </Button>
                            </Box>
                        )}

                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle2" gutterBottom>
                                Transcript (Optional)
                            </Typography>
                            <Button
                                component="label"
                                variant="outlined"
                                startIcon={<CloudUploadIcon />}
                                fullWidth
                            >
                                {transcript ? transcript.name : 'Upload Transcript (PDF/TXT)'}
                                <input
                                    type="file"
                                    hidden
                                    accept=".pdf,.txt"
                                    onChange={handleFileChange}
                                />
                            </Button>
                        </Box>
                    </>
                );
            case 2:
                return (
                    <Box sx={{ mt: 2 }}>
                        {/* Existing supporting documents */}
                        {editMode && supportingDocs.length > 0 && (
                            <Box sx={{ mb: 2 }}>
                                {supportingDocs.map((doc) => {
                                    const isKept = docsToKeep.find(d => d.id === doc.id)?.keep !== false;
                                    return (
                                        <Box 
                                            key={doc.id}
                                            sx={{ 
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: 1,
                                                opacity: isKept ? 1 : 0.5
                                            }}
                                        >
                                            <Typography variant="body2" sx={{ flex: 1 }}>
                                                {doc.document_name}
                                            </Typography>
                                            <IconButton 
                                                size="small"
                                                onClick={() => handleSupportingDocDelete(doc.id)}
                                                color={isKept ? "error" : "primary"}
                                            >
                                                {isKept ? <DeleteIcon /> : <UndoIcon />}
                                            </IconButton>
                                        </Box>
                                    );
                                })}
                            </Box>
                        )}

                        {/* New supporting documents */}
                        {newSupportingDocs.length > 0 && (
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    New Documents:
                                </Typography>
                                {newSupportingDocs.map((file, index) => (
                                    <Box 
                                        key={index}
                                        sx={{ 
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: 1
                                        }}
                                    >
                                        <Typography variant="body2" sx={{ flex: 1 }}>
                                            {file.name}
                                        </Typography>
                                        <IconButton 
                                            size="small"
                                            onClick={() => handleNewSupportingDocDelete(index)}
                                            color="error"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                        )}

                        {/* Upload button */}
                        <Button
                            component="label"
                            variant="outlined"
                            startIcon={<CloudUploadIcon />}
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Add Supporting Documents
                            <input
                                type="file"
                                hidden
                                multiple
                                accept=".pdf,.txt,.doc,.docx,.csv"
                                onChange={handleSupportingDocsChange}
                            />
                        </Button>

                        {/* Add Email Notification Toggle */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendEmailNotification}
                                    onChange={(e) => setSendEmailNotification(e.target.checked)}
                                    name="sendEmailNotification"
                                    color="primary"
                                />
                            }
                            label="Send email notification to course users"
                            sx={{ mt: 2, display: 'block' }}
                        />
                    </Box>
                );
            default:
                return null;
        }
    };

    const renderVideoList = () => {
        const videosByCourse = {};
        
        // Only organize videos for the selected course
        if (selectedCourseId && courses.length > 0) {
            const selectedCourse = courses.find(c => c.id === selectedCourseId);
            if (selectedCourse) {
                videosByCourse[selectedCourse.course_name] = videos.filter(video => 
                    video.associated_course === selectedCourse.course_name
                );
            }
        }
        
        return (
            <Paper sx={{ 
                mt: 4, 
                p: 4,
                backgroundColor: 'background.light',
                boxShadow: 'none',
                borderRadius: 2
            }}>
                {Object.entries(videosByCourse).map(([course, courseVideos]) => (
                    <Box key={course}>
                        <Typography 
                            variant="h5" 
                            gutterBottom
                            sx={{
                                fontWeight: 500,
                                mb: 3,
                                color: 'text.primary',
                                pt: { xs: 2, sm: 0 }
                            }}
                        >
                            Course Videos
                        </Typography>
                        
                        <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
                            {course} ({courseVideos.length} videos)
                        </Typography>
                        
                        {courseVideos.length === 0 ? (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2,
                                mt: 4,
                                mb: 4
                            }}>
                                <VideoLibraryIcon sx={{ 
                                    fontSize: 48,
                                    color: 'text.secondary',
                                    opacity: 0.5
                                }} />
                                <Typography variant="h6" color="text.secondary">
                                    No Videos Found
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400, textAlign: 'center' }}>
                                    This course doesn't have any videos yet. Click the "Add Videos" button in the course list to add your first video.
                                </Typography>
                            </Box>
                        ) : (
                            <Accordion 
                                expanded={true}
                                sx={{ 
                                    mb: 2,
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    '&:before': {
                                        display: 'none',
                                    }
                                }}
                            >
                                <AccordionDetails>
                                    {courseVideos.map((video) => (
                                        <Box 
                                            key={video.id}
                                            sx={{
                                                p: 2,
                                                mb: 2,
                                                borderRadius: '8px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                            }}
                                        >
                                            {/* Header with title and action buttons */}
                                            <Box sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-start',
                                                mb: 2
                                            }}>
                                                <Typography 
                                                    variant="subtitle1" 
                                                    sx={{ fontWeight: 500 }}
                                                >
                                                    {video.title}
                                                </Typography>

                                                {/* Action buttons */}
                                                <Box sx={{ 
                                                    display: 'flex', 
                                                    gap: 1, 
                                                }}>
                                                    <IconButton 
                                                        size="small"
                                                        sx={{ 
                                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(255, 255, 255, 0.2)'
                                                            }
                                                        }}
                                                        onClick={() => handleEdit(video)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton 
                                                        size="small"
                                                        sx={{ 
                                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(255, 255, 255, 0.2)'
                                                            }
                                                        }}
                                                        onClick={() => handleDelete(video.id)}
                                                        disabled={isDeleting}
                                                    >
                                                        {isDeleting ? (
                                                            <CircularProgress size={20} />
                                                        ) : (
                                                            <DeleteIcon />
                                                        )}
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                            {/* Video Source */}
                                            <Typography 
                                                variant="body2" 
                                                color="text.secondary" 
                                                sx={{ 
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 0.5,
                                                    mb: 2
                                                }}
                                            >
                                                {video.url ? <YouTubeIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
                                                {video.url ? (
                                                    <Link 
                                                        href={video.url} 
                                                        target="_blank" 
                                                        rel="noopener"
                                                        sx={{ 
                                                            color: 'primary.main',
                                                            textDecoration: 'none',
                                                            '&:hover': {
                                                                textDecoration: 'underline'
                                                            }
                                                        }}
                                                    >
                                                        YouTube Video
                                                    </Link>
                                                ) : (
                                                    <Link 
                                                        href={`${BASE_URL}/${video.mp4_path}`} 
                                                        target="_blank" 
                                                        rel="noopener"
                                                        sx={{ 
                                                            color: 'primary.main',
                                                            textDecoration: 'none',
                                                            '&:hover': {
                                                                textDecoration: 'underline'
                                                            }
                                                        }}
                                                    >
                                                        MP4 Video
                                                    </Link>
                                                )}
                                            </Typography>

                                            {/* Transcript Badge */}
                                            {video.transcript_file && (
                                                <Box
                                                    sx={{
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                        borderRadius: '6px',
                                                        px: 1.5,
                                                        py: 0.5,
                                                    }}
                                                >
                                                    <Typography 
                                                        variant="body2" 
                                                        color="text.secondary"
                                                        sx={{ fontSize: '0.75rem' }}
                                                    >
                                                        Transcript: {video.transcript_file.split('/').pop()}
                                                    </Typography>
                                                </Box>
                                            )}

                                            {/* Supporting Documents List */}
                                            {video.supporting_documents?.length > 0 && (
                                                <Box sx={{ mt: 2 }}>
                                                    <Typography 
                                                        variant="body2" 
                                                        color="text.secondary"
                                                        sx={{ 
                                                            fontSize: '0.75rem',
                                                            mb: 1
                                                        }}
                                                    >
                                                        Supporting Documents:
                                                    </Typography>
                                                    <Box 
                                                        component="ul" 
                                                        sx={{ 
                                                            listStyle: 'none',
                                                            p: 0,
                                                            m: 0
                                                        }}
                                                    >
                                                        {video.supporting_documents.map((doc, index) => (
                                                            <Box
                                                                component="li"
                                                                key={index}
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 1,
                                                                    mb: 0.5
                                                                }}
                                                            >
                                                                <Typography 
                                                                    variant="body2" 
                                                                    sx={{ 
                                                                        fontSize: '0.75rem',
                                                                        color: 'text.secondary'
                                                                    }}
                                                                >
                                                                    •
                                                                </Typography>
                                                                <Link
                                                                    href={`${BASE_URL}/${doc.document_path}`}
                                                                    target="_blank"
                                                                    rel="noopener"
                                                                    sx={{ 
                                                                        color: 'primary.main',
                                                                        textDecoration: 'none',
                                                                        fontSize: '0.75rem',
                                                                        '&:hover': {
                                                                            textDecoration: 'underline'
                                                                        }
                                                                    }}
                                                                >
                                                                    {doc.document_name}
                                                                </Link>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                ))}
            </Paper>
        );
    };

    const handleSupportingDocDelete = (docId) => {
        setDocsToKeep(prev => {
            const existingDoc = prev.find(doc => doc.id === docId);
            if (existingDoc) {
                return prev.map(doc => 
                    doc.id === docId ? { ...doc, keep: !doc.keep } : doc
                );
            } else {
                return [...prev, { id: docId, keep: false }];
            }
        });
    };

    const handleNewSupportingDocDelete = (index) => {
        setNewSupportingDocs(prev => prev.filter((_, i) => i !== index));
    };

    const handleSupportingDocsChange = (event) => {
        const files = Array.from(event.target.files);
        setNewSupportingDocs(prev => [...prev, ...files]);
    };

    return (
        <Box sx={{ 
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            p: 2,
            pt: { xs: 3, sm: 2 },
            mt: { xs: 4, sm: 0 }
        }}>
            <Box sx={{ 
                width: '100%',
                maxWidth: '800px'
            }}>
                {!isInitialized || isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : !selectedCourseId ? (
                    <Paper sx={{ 
                        p: 4, 
                        textAlign: 'center',
                        backgroundColor: 'background.light',
                        boxShadow: 'none',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}>
                        <VideoLibraryIcon sx={{ 
                            fontSize: 48,
                            color: 'text.secondary',
                            opacity: 0.5
                        }} />
                        <Typography variant="h6" color="text.secondary">
                            No Course Selected
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400 }}>
                            Please select a course from the course list to manage its videos
                        </Typography>
                    </Paper>
                ) : renderVideoList()}
                
                <Dialog 
                    open={openModal} 
                    onClose={handleCloseModal}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        Edit Video
                        <IconButton
                            onClick={handleCloseModal}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
                            {['Course & Title', 'Video Details', 'Supporting Documents'].map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            {renderStepContent()}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {activeStep > 0 && (
                            <Button onClick={handleBack}>
                                Back
                            </Button>
                        )}
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={
                                isLoading || 
                                (activeStep === 0 && !isStepOneValid()) || 
                                (activeStep === 1 && !isStepTwoValid())
                            }
                        >
                            {activeStep === 2 
                                ? (isLoading ? 'Processing...' : 'Update Video')
                                : 'Next'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>

                <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
                    <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
                        {success}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}

export default LMSManageVideos; 