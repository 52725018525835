import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyToken } from './auth';

function ProtectedRoute({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuth = verifyToken();

    useEffect(() => {
        if (!isAuth) {
            // Clear any invalid tokens
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            
            navigate('/login', { 
                replace: true, 
                state: { from: location } 
            });
        }
    }, [isAuth, navigate, location]);

    return isAuth ? children : null;
}

export default ProtectedRoute;

