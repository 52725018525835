import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Box, Container, Typography, Grid, Card, CardContent, 
    CardActionArea, CircularProgress, List, ListItem, 
    ListItemText, Divider, Snackbar, Alert, Accordion, AccordionSummary, AccordionDetails, Button, Paper, IconButton, Collapse, LinearProgress, CssBaseline, Toolbar, Tabs, Tab, CardMedia, Chip, TextField, InputAdornment 
} from '@mui/material';
import { getToken, getUserId, getRoleId, getClientId, isSuperAdmin } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { styled } from '@mui/material/styles';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const PlaceholderBox = styled(Box)(({ theme }) => ({
    height: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.05)' 
        : 'rgba(0, 0, 0, 0.03)',
    borderBottom: `1px solid ${theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.1)' 
        : 'rgba(0, 0, 0, 0.1)'}`,
}));

function CourseList() {
    const userId = getUserId();
    const userClientId = getClientId();
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [expandedCourse, setExpandedCourse] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState({});
    const [courseProgress, setCourseProgress] = useState({});
    const [activeTab, setActiveTab] = useState(() => 
        localStorage.getItem('courseListActiveTab') || 'enrolled'
    );
    const [enrolledCourses, setEnrolledCourses] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [courseTypeFilter, setCourseTypeFilter] = useState(() => 
        localStorage.getItem('courseTypeFilter') || 'regular'
    );

    const navigate = useNavigate();
    const theme = useTheme();
    const token = getToken();

    useEffect(() => {
        fetchCourses();
        fetchEnrolledCourses();
        console.log("All courses:", courses); // Debug log
    }, [token]);

    useEffect(() => {
        if (courses.length > 0) {
            courses.forEach(course => {
                fetchCourseProgress(course.id);
            });
        }
    }, [courses]);

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch courses');
            }
            const data = await response.json();
            setCourses(data);
            data.forEach(course => {
                fetchCourseProgress(course.id);
            });
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setSnackbarMessage('Error loading courses');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            setIsLoading(false);
        }
    };

    const fetchVideos = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/getlmsvideos?course_id=${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch videos');
            }
            const data = await response.json();
            console.log('Fetched videos:', data);
            setVideos(data);
            setSelectedCourse(courseId);
        } catch (error) {
            console.error('Error fetching videos:', error);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleVideoClick = (video, courseId) => {

        navigate(`/video-player`, {
            state: { 
                video,
                courseId,
                courseName: selectedCourse,
                videos: videos
            }
        });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    const fetchCourseProgress = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/course-progress/${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) throw new Error('Failed to fetch progress');
            const data = await response.json();
            
            // Filter out videos with no duration
            const validVideos = data.filter(video => video.total_duration > 0);
            
            if (validVideos.length === 0) {
                setCourseProgress(prev => ({
                    ...prev,
                    [courseId]: {
                        percent: 0,
                        videos: data
                    }
                }));
                return;
            }

            // Calculate weight per video (equal distribution)
            const weightPerVideo = 100 / data.length;

            // Calculate weighted progress for each video
            let totalProgress = 0;
            data.forEach(video => {
                if (!video.total_duration) return;
                const individualProgress = Math.min(
                    (video.watched_duration / video.total_duration) * 100, 
                    100
                );
                const weightedProgress = (individualProgress * weightPerVideo) / 100;
                totalProgress += weightedProgress;
            });

            setCourseProgress(prev => ({
                ...prev,
                [courseId]: {
                    percent: Math.min(totalProgress, 100),
                    videos: data
                }
            }));
        } catch (error) {
            console.error('Error fetching course progress:', error);
        }
    };

    const CourseProgress = ({ courseId }) => {
        const progress = courseProgress[courseId];
        
        return (
            <Box sx={{ mt: 2, mb: 1 }}>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 1 
                }}>
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ fontSize: '0.875rem' }}
                    >
                        Progress
                    </Typography>
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'primary.main',
                            fontWeight: 500,
                            fontSize: '0.875rem'
                        }}
                    >
                        {progress ? Math.round(progress.percent) : 0}%
                    </Typography>
                </Box>
                <LinearProgress 
                    variant="determinate" 
                    value={progress ? progress.percent : 0}
                    sx={{
                        height: 4,
                        borderRadius: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        '& .MuiLinearProgress-bar': {
                            borderRadius: 2,
                            backgroundColor: 'primary.main',
                        }
                    }}
                />
            </Box>
        );
    };

    const getVideoProgress = (videoId, courseId) => {
        const progress = courseProgress[courseId];
        if (!progress || !progress.videos) return 0;
        
        const video = progress.videos.find(v => v.video_id === videoId);
        if (!video || !video.total_duration || video.total_duration <= 0) {
            console.log(`No valid duration for video ${videoId}:`, video);
            return 0;
        }
        
        const percentage = (video.watched_duration / video.total_duration) * 100;
        console.log(`Progress for video ${videoId}:`, {
            watched: video.watched_duration,
            total: video.total_duration,
            percentage
        });
        
        return Math.min(percentage, 100);
    };

    const handleCourseClick = (course) => {
        // Check if user is admin or superadmin
        const userRoleId = getRoleId();
        const isAdminOrSuperAdmin = userRoleId === 1 || userRoleId === 2;
        
        // Check if the course is in enrolledCourses
        const isEnrolled = enrolledCourses.some(ec => ec.id === course.id);
        
        if (!isEnrolled && !isAdminOrSuperAdmin) {
            // If not enrolled and not admin/superadmin, show message to enroll first
            setSnackbarMessage('Please enroll in the course first');
            setSnackbarSeverity('info');
            setOpenSnackbar(true);
            return;
        }

        // If enrolled or admin/superadmin, proceed with navigation
        navigate(`/course-details/${course.id}`, {
            state: { 
                course,
                courseId: course.id,
                courseName: course.course_name
            }
        });
    };

    const fetchEnrolledCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/enrolled-courses`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch enrolled courses');
            const data = await response.json();
            setEnrolledCourses(data);
        } catch (error) {
            console.error('Error fetching enrolled courses:', error);
            setSnackbarMessage('Error loading enrolled courses');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleEnrollCourse = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/enroll-course/${courseId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to enroll');
            }

            // Show success message
            setSnackbarMessage('Successfully enrolled in course');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);

            // Refresh both course lists
            fetchEnrolledCourses();
            fetchCourses();
        } catch (error) {
            console.error('Error enrolling in course:', error);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    // const handleNotifyUsers = async (courseId) => {
    //     try {
    //         const response = await fetch(`${BASE_URL}/api/notify-course-users/${courseId}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         });

    //         const data = await response.json();
            
    //         if (!response.ok) {
    //             throw new Error(data.error || 'Failed to send notifications');
    //         }

    //         setSnackbarMessage(`Notifications sent successfully to ${data.recipientCount} users`);
    //         setSnackbarSeverity('success');
    //         setOpenSnackbar(true);
    //     } catch (error) {
    //         console.error('Error sending notifications:', error);
    //         setSnackbarMessage(error.message);
    //         setSnackbarSeverity('error');
    //         setOpenSnackbar(true);
    //     }
    // };

    const handleResume = async (courseId, e) => {
        e.stopPropagation();
        try {
            const response = await fetch(`${BASE_URL}/api/last-watched/${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch last watched video');
            }

            const data = await response.json();
            
            if (data.video) {
                navigate('/video-player', {
                    state: {
                        video: {
                            ...data.video,
                            videoType: data.video.mp4_path ? 'mp4' : 'youtube'
                        },
                        courseId: data.courseId,
                        courseName: data.course.course_name,
                        courseDescription: data.course.course_description,
                        videos: data.videos.map(v => ({
                            ...v,
                            videoType: v.mp4_path ? 'mp4' : 'youtube'
                        })),
                        startTime: parseInt(data.startTime),
                        course: data.course,
                        lastWatchedPosition: parseInt(data.startTime)
                    }
                });
            } else {
                setSnackbarMessage('No videos available in this course');
                setSnackbarSeverity('info');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error resuming course:', error);
            setSnackbarMessage('Failed to resume course');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const renderCourseList = (courses) => (
        <Grid container spacing={3}>
            {courses.map((course) => {
                const isEnrolled = enrolledCourses.some(ec => ec.id === course.id);
                const isSuperAdminUser = isSuperAdmin();
                const isAdminUser = getRoleId() === 2;
                const isOracleCourse = course.course_type === 'oracle';
                const hasVideos = course.has_videos; // Assuming this property exists in your course object
                
                return (
                    <Grid item xs={12} sm={6} md={4} key={course.id}>
                        <Card 
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: 1,
                            }}
                        >
                            {/* Status Chip */}
                            <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 2 }}>
                                <Chip
                                    label={course.is_public ? "Public" : "Private"}
                                    size="small"
                                    sx={{ 
                                        backgroundColor: course.is_public 
                                            ? 'rgba(46, 125, 50, 0.9)' 
                                            : 'rgba(0, 0, 0, 0.6)',
                                        color: 'white',
                                        fontWeight: 500
                                    }}
                                />
                            </Box>

                            {/* Course Image/Placeholder Section */}
                            <Box sx={{ position: 'relative', pt: '56.25%', overflow: 'hidden' }}>
                                {(isEnrolled || isSuperAdminUser || isAdminUser) ? (
                                    <CardActionArea 
                                        onClick={() => handleCourseClick(course)}
                                        sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                                    >
                                        {renderCourseContent(course)}
                                    </CardActionArea>
                                ) : (
                                    renderCourseContent(course)
                                )}
                            </Box>

                            {/* Course Content Section */}
                            <CardContent sx={{ 
                                flexGrow: 1, 
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                p: 2
                            }}>
                                <Typography 
                                    variant="h6" 
                                    component="h2"
                                    sx={{ 
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2
                                    }}
                                >
                                    {course.course_name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        mb: 2
                                    }}
                                >
                                    {course.course_description || "No description available."}
                                </Typography>

                                {/* Progress Section */}
                                {isEnrolled && !isOracleCourse && (
                                    <Box sx={{ mt: 'auto' }}>
                                        <CourseProgress courseId={course.id} />
                                    </Box>
                                )}
                            </CardContent>

                            {/* Action Buttons Section */}
                            <Box sx={{ p: 2, pt: 0 }}>
                                {isEnrolled && (!isOracleCourse || (isOracleCourse && hasVideos)) && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        startIcon={<PlayArrowIcon />}
                                        onClick={(e) => handleResume(course.id, e)}
                                        sx={{ mb: activeTab === 'public' ? 1 : 0 }}
                                    >
                                        Resume Course
                                    </Button>
                                )}
                                {activeTab === 'public' && !isEnrolled && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEnrollCourse(course.id);
                                        }}
                                    >
                                        Enroll Now
                                    </Button>
                                )}
                            </Box>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );

    const renderCourseContent = (course) => (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.background.default
            }}
        >
            {course.image_path ? (
                <CardMedia
                    component="img"
                    sx={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                    image={`${BASE_URL}/uploads${course.image_path}`}
                    alt={course.course_name}
                    onError={(e) => {
                        e.target.style.display = 'none';
                        const placeholder = document.createElement('div');
                        e.target.parentElement.appendChild(placeholder);
                        placeholder.style.cssText = `
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: ${theme.palette.mode === 'dark' 
                                ? 'rgba(255, 255, 255, 0.05)' 
                                : 'rgba(0, 0, 0, 0.03)'};
                        `;
                        placeholder.innerHTML = '<div class="placeholder-icon"><AutoStoriesIcon style="width: 48px; height: 48px; opacity: 0.5;" /></div>';
                    }}
                />
            ) : (
                <Box
                    sx={{ 
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.05)' 
                            : 'rgba(0, 0, 0, 0.03)',
                    }}
                >
                    <AutoStoriesIcon 
                        sx={{ 
                            width: 48, 
                            height: 48, 
                            opacity: 0.5,
                            color: theme.palette.text.secondary
                        }} 
                    />
                </Box>
            )}
        </Box>
    );

    // Define filterCoursesByType first
    const filterCoursesByType = (courses, type) => {
        return courses.filter(course => {
            if (type === 'regular') {
                // Consider courses with no type or type='regular' as regular courses
                return !course.course_type || course.course_type === 'regular';
            } else {
                // For Oracle courses, explicitly check for 'oracle' type
                return course.course_type === 'oracle';
            }
        });
    };

    // Then define filterCourses
    const filterCourses = () => {
        if (isSuperAdmin()) {
            return {
                enrolledCourses: courses.filter(course => 
                    enrolledCourses.some(ec => ec.id === course.id) &&
                    (courseTypeFilter === 'oracle' ? course.course_type === 'oracle' : 
                    (!course.course_type || course.course_type === 'regular'))
                ),
                publicCourses: courses.filter(course => 
                    course.is_public &&
                    (courseTypeFilter === 'oracle' ? course.course_type === 'oracle' : 
                    (!course.course_type || course.course_type === 'regular'))
                ),
                privateCourses: courses.filter(course => 
                    !course.is_public &&
                    (courseTypeFilter === 'oracle' ? course.course_type === 'oracle' : 
                    (!course.course_type || course.course_type === 'regular'))
                )
            };
        }

        return {
            enrolledCourses: courses.filter(course => 
                enrolledCourses.some(ec => ec.id === course.id) &&
                (courseTypeFilter === 'oracle' ? course.course_type === 'oracle' : 
                (!course.course_type || course.course_type === 'regular'))
            ),
            publicCourses: courses.filter(course => 
                course.is_public && 
                course.access_clients.includes(Number(userClientId)) &&
                (courseTypeFilter === 'oracle' ? course.course_type === 'oracle' : 
                (!course.course_type || course.course_type === 'regular'))
            ),
            privateCourses: courses.filter(course => 
                !course.is_public && 
                course.assigned_users && 
                Array.isArray(course.assigned_users) &&
                course.assigned_users.some(user => Number(user.user_id) === Number(userId)) &&
                (courseTypeFilter === 'oracle' ? course.course_type === 'oracle' : 
                (!course.course_type || course.course_type === 'regular'))
            )
        };
    };

    const handleCourseTypeChange = (event, newType) => {
        if (newType !== null) {
            setCourseTypeFilter(newType);
            localStorage.setItem('courseTypeFilter', newType);
        }
    };

    const renderCourseTypeIndicator = (course) => {
        const isOracle = course.course_type === 'oracle';
        return (
            <Box sx={{ 
                position: 'absolute', 
                top: 16, 
                left: 16, 
                zIndex: 2,
                display: 'flex',
                gap: 1
            }}>
                <Chip
                    icon={isOracle ? <SmartToyIcon /> : <MenuBookIcon />}
                    label={isOracle ? "Oracle" : "Regular"}
                    size="small"
                    sx={{ 
                        backgroundColor: isOracle 
                            ? 'rgba(25, 118, 210, 0.9)' 
                            : 'rgba(46, 125, 50, 0.9)',
                        color: 'white',
                        fontWeight: 500,
                        '& .MuiChip-icon': {
                            color: 'white'
                        }
                    }}
                />
            </Box>
        );
    };

    // Update the tab change handler
    const handleTabChange = (e, newValue) => {
        setActiveTab(newValue);
        localStorage.setItem('courseListActiveTab', newValue);
    };

    // Update the search handler to switch tabs automatically
    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // If search is empty, don't switch tabs
        if (!query) return;

        // Check each category for matches
        const { enrolledCourses, publicCourses, privateCourses } = filterCourses();
        
        const matchingCourses = {
            enrolled: enrolledCourses.filter(course => 
                course.course_name.toLowerCase().includes(query.toLowerCase()) ||
                course.course_description?.toLowerCase().includes(query.toLowerCase())
            ),
            public: publicCourses.filter(course => 
                course.course_name.toLowerCase().includes(query.toLowerCase()) ||
                course.course_description?.toLowerCase().includes(query.toLowerCase())
            ),
            private: privateCourses.filter(course => 
                course.course_name.toLowerCase().includes(query.toLowerCase()) ||
                course.course_description?.toLowerCase().includes(query.toLowerCase())
            )
        };

        // Switch to the first tab that has matches
        if (matchingCourses.enrolled.length > 0) {
            setActiveTab('enrolled');
        } else if (matchingCourses.public.length > 0) {
            setActiveTab('public');
        } else if (matchingCourses.private.length > 0) {
            setActiveTab('private');
        }
    };

    // Add this function to find which tab contains the course
    const findCourseTab = (courseId) => {
        if (enrolledCourses.some(c => c.id === courseId)) return 'enrolled';
        const { publicCourses, privateCourses } = filterCourses();
        if (publicCourses.some(c => c.id === courseId)) return 'public';
        if (privateCourses.some(c => c.id === courseId)) return 'private';
        return null;
    };

    // Add this function to filter courses based on search
    const getFilteredCourses = (courses) => {
        if (!searchQuery) return courses;
        return courses.filter(course => 
            course.course_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.course_description?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ 
            backgroundColor: 'background.default', 
            flexGrow: 1, 
            minHeight: '100vh',
            pt: { xs: '35px', md: '24px' }, // Responsive top padding
        }}>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ 
                py: { xs: 2, md: 3 }, // Reduced top padding for mobile
                px: { xs: 2, sm: 3 } // Maintain consistent horizontal padding
            }}>
                {/* Header Section */}
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 2, md: 0 },
                    justifyContent: 'space-between', 
                    alignItems: { xs: 'stretch', md: 'center' },
                    mb: 3,
                }}>
                    <Typography 
                        variant="h5" 
                        component="h1"
                        sx={{ 
                            textAlign: { xs: 'center', md: 'left' }
                        }}
                    >
                        Course Library
                    </Typography>
                    
                    {/* Course Type Toggle - Full width on mobile */}
                    <ToggleButtonGroup
                        value={courseTypeFilter}
                        exclusive
                        onChange={handleCourseTypeChange}
                        aria-label="course type"
                        sx={{
                            backgroundColor: 'background.paper',
                            width: { xs: '100%', md: 'auto' },
                            '& .MuiToggleButton-root': {
                                flex: { xs: 1, md: 'initial' },
                            },
                            '& .MuiToggleButton-root.Mui-selected': {
                                backgroundColor: 'primary.main',
                                color: 'primary.contrastText',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            },
                        }}
                    >
                        <ToggleButton 
                            value="regular" 
                            aria-label="regular courses"
                            sx={{ px: { xs: 2, md: 3 } }}
                        >
                            <MenuBookIcon sx={{ mr: 1 }} />
                            <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>
                                Courses
                            </Box>
                        </ToggleButton>
                        <ToggleButton 
                            value="oracle" 
                            aria-label="oracle courses"
                            sx={{ px: { xs: 2, md: 3 } }}
                        >
                            <SmartToyIcon sx={{ mr: 1 }} />
                            <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>
                                Oracle
                            </Box>
                        </ToggleButton>
                    </ToggleButtonGroup>

                    {/* Search Field - Full width on mobile */}
                    <TextField
                        size="small"
                        placeholder="Search courses..."
                        value={searchQuery}
                        onChange={handleSearch}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ 
                            width: { xs: '100%', md: 250 }
                        }}
                    />
                </Box>

                {/* Tabs - Scrollable on mobile */}
                <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{ 
                        mb: 3,
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .MuiTab-root': {
                            textTransform: 'none',
                            minWidth: { xs: 'auto', sm: 100 },
                            px: { xs: 2, sm: 3 },
                        }
                    }}
                >
                    <Tab value="enrolled" label="Enrolled" />
                    <Tab value="public" label="Public" />
                    <Tab value="private" label="Private" />
                </Tabs>

                {/* Course Grid - Adjust columns based on screen size */}
                <Grid container spacing={2}>
                    {getFilteredCourses(
                        filterCoursesByType(
                            activeTab === 'enrolled' ? filterCourses().enrolledCourses :
                            activeTab === 'public' ? filterCourses().publicCourses :
                            filterCourses().privateCourses,
                            courseTypeFilter
                        )
                    )
                    .map((course) => (
                        <Grid item xs={12} sm={6} lg={4} key={course.id}>
                            <Card 
                                sx={{ 
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative'
                                }}
                            >
                                {/* Course Type and Status Chips - Adjust spacing */}
                                <Box sx={{ 
                                    position: 'absolute', 
                                    top: 8, 
                                    left: 8, 
                                    right: 8,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    zIndex: 2,
                                    flexWrap: 'wrap',
                                    gap: 1
                                }}>
                                    {/* Course Type Chip */}
                                    <Chip
                                        icon={course.course_type === 'oracle' ? <SmartToyIcon /> : <MenuBookIcon />}
                                        label={course.course_type === 'oracle' ? "Oracle" : "Regular"}
                                        size="small"
                                        sx={{ 
                                            backgroundColor: course.course_type === 'oracle'
                                                ? 'rgba(25, 118, 210, 0.9)'
                                                : 'rgba(46, 125, 50, 0.9)',
                                            color: 'white',
                                            fontWeight: 500,
                                            '& .MuiChip-icon': {
                                                color: 'white'
                                            }
                                        }}
                                    />
                                    
                                    {/* Status Chip */}
                                    <Chip
                                        label={course.is_public ? "Public" : "Private"}
                                        size="small"
                                        sx={{ 
                                            backgroundColor: course.is_public 
                                                ? 'rgba(46, 125, 50, 0.9)'
                                                : 'rgba(0, 0, 0, 0.6)',
                                            color: 'white',
                                            fontWeight: 500
                                        }}
                                    />
                                </Box>

                                {/* Course Image/Placeholder Section */}
                                <Box sx={{ position: 'relative', pt: '56.25%', overflow: 'hidden' }}>
                                    {(enrolledCourses.some(ec => ec.id === course.id) || isSuperAdmin()) ? (
                                        <CardActionArea 
                                            onClick={() => handleCourseClick(course)}
                                            sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                                        >
                                            {renderCourseContent(course)}
                                        </CardActionArea>
                                    ) : (
                                        renderCourseContent(course)
                                    )}
                                </Box>

                                {/* Course Content Section */}
                                <CardContent sx={{ 
                                    flexGrow: 1, 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    p: 2
                                }}>
                                    <Typography 
                                        variant="h6" 
                                        component="h2"
                                        sx={{ 
                                            fontWeight: 500,
                                            mb: 1,
                                            lineHeight: 1.2
                                        }}
                                    >
                                        {course.course_name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            mb: 2
                                        }}
                                    >
                                        {course.course_description || "No description available."}
                                    </Typography>

                                    {/* Progress Section - Show only if enrolled AND has videos */}
                                    {enrolledCourses.some(ec => ec.id === course.id) && 
                                     course.has_videos && (
                                        <Box sx={{ mt: 'auto' }}>
                                            <CourseProgress courseId={course.id} />
                                        </Box>
                                    )}
                                </CardContent>

                                {/* Action Buttons Section */}
                                <Box sx={{ p: 2, pt: 0 }}>
                                    {/* Resume Course Button - Show only if enrolled AND has videos */}
                                    {enrolledCourses.some(ec => ec.id === course.id) && 
                                     course.has_videos && (
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            startIcon={<PlayArrowIcon />}
                                            onClick={(e) => handleResume(course.id, e)}
                                            sx={{ mb: activeTab === 'public' ? 1 : 0 }}
                                        >
                                            Resume Course
                                        </Button>
                                    )}
                                    {activeTab === 'public' && !enrolledCourses.some(ec => ec.id === course.id) && (
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEnrollCourse(course.id);
                                            }}
                                        >
                                            Enroll Now
                                        </Button>
                                    )}
                                </Box>

                                {/* Add Oracle-specific content if needed */}
                                {course.course_type === 'oracle' && (
                                    <Box sx={{ p: 2, pt: 0 }}>
                                        {course.course_documents && course.course_documents.length > 0 && (
                                            <Typography variant="body2" color="text.secondary">
                                                <strong>Documents:</strong> {course.course_documents.length}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* Snackbar - Adjust position for mobile */}
            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={handleSnackbarClose}
                sx={{
                    bottom: { xs: 90, sm: 24 }  // Adjust bottom spacing for mobile
                }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default CourseList; 