import React, { useState, useEffect } from 'react';
import { 
  Box, Card, CardContent, Typography, Avatar, CardMedia, Button, 
  CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Select, MenuItem, FormControl, InputLabel, Switch, FormControlLabel,
  Divider, IconButton, Skeleton
} from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ImageIcon from '@mui/icons-material/Image';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import Comments from './Comments';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { NavBarContext } from '../navigation/NavBarContext';
import { useInView } from 'react-intersection-observer';
import BusinessIcon from '@mui/icons-material/Business';

function Feed() {
  // Define reactions array at the top of the component
  const reactions = [
    { 
      icon: <ThumbUpOutlinedIcon />, 
      label: 'Like',
      color: '#0A66C2' // LinkedIn blue
    },
    { 
      icon: <CelebrationOutlinedIcon />, 
      label: 'Celebrate',
      color: '#593DEA' // Purple
    },
    { 
      icon: <EmojiObjectsOutlinedIcon />, 
      label: 'Insightful',
      color: '#F5B668' // Orange
    },
    { 
      icon: <HandshakeOutlinedIcon />, 
      label: 'Support',
      color: '#6DAE67' // Green
    },
    { 
      icon: <FavoriteOutlinedIcon />, 
      label: 'Love',
      color: '#DF704D' // Red
    },
    { 
      icon: <EmojiEmotionsOutlinedIcon />, 
      label: 'Funny',
      color: '#F1C40F' // Yellow/Gold
    }
  ];

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // New states for create post dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [newPost, setNewPost] = useState({
    title: '',
    content: '',
    post_type: 'general_post'
  });
  const [courses, setCourses] = useState([]);
  const [videos, setVideos] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [showReactions, setShowReactions] = useState(null);
  const [userReactions, setUserReactions] = useState({});
  const [submittingReactions, setSubmittingReactions] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [showComments, setShowComments] = useState({});
  const { openNavBar: open } = React.useContext(NavBarContext);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { ref, inView } = useInView();

  useEffect(() => {
    fetchCurrentUser();
    fetchFeedPosts();
    fetchCourses();
    fetchVideos();
  }, []);

  // Fetch courses for dropdown
  const fetchCourses = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/courses`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setCourses(data);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  // Fetch videos for dropdown
  const fetchVideos = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/lmsvideos`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setVideos(data);
      }
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  // Handle creating new post
  const handleCreatePost = async (formData) => {
    setSubmitting(true);
    try {
      const response = await fetch(`${BASE_URL}/api/feed/posts`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Failed to create post');
      }

      // Refresh feed and close dialog
      await fetchFeedPosts();
      handleCloseDialog();
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post');
    } finally {
      setSubmitting(false);
    }
  };

  const resetPostForm = () => {
    setNewPost({
      title: '',
      content: '',
      post_type: 'general_post'
    });
    setImageFile(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    resetPostForm();
  };

  const fetchFeedPosts = async (pageNum = 1) => {
    try {
      const response = await fetch(`${BASE_URL}/api/feed?page=${pageNum}&limit=10`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch feed posts');
      }

      const data = await response.json();
      
      if (pageNum === 1) {
        setPosts(data.posts);
      } else {
        setPosts(prev => [...prev, ...data.posts]);
      }
      
      setHasMore(data.pagination.hasMore);
    } catch (error) {
      console.error('Error fetching feed:', error);
      setError('Failed to load feed content');
    } finally {
      setLoading(false);
    }
  };

  // Add useEffect for infinite scroll
  useEffect(() => {
    if (inView && hasMore && !loading) {
      setPage(prev => prev + 1);
      fetchFeedPosts(page + 1);
    }
  }, [inView, hasMore, loading]);

  const getAvatarColor = (userId) => {
    // Array of pleasant, muted colors
    const colors = [
      '#7B4FFF', // Purple
      '#FF6B6B', // Coral
      '#4ECDC4', // Teal
      '#45B7D1', // Sky Blue
      '#96C93D', // Lime
      '#E4A663', // Orange
      '#7E57C2', // Deep Purple
      '#26A69A', // Green
      '#5C6BC0', // Indigo
      '#FF8A65', // Deep Orange
      '#78909C', // Blue Grey
      '#42A5F5', // Blue
      '#66BB6A', // Light Green
      '#EC407A', // Pink
      '#FFA726', // Orange

    ];
    
    // Use userId to consistently pick a color
    const colorIndex = userId % colors.length;
    return colors[colorIndex];
  };

  const handleReaction = async (postId, reactionType, isMainButton = false) => {
    if (submittingReactions[postId]) return;
    
    // Set loading state for specific post
    setSubmittingReactions(prev => ({
      ...prev,
      [postId]: true
    }));

    try {
      const currentReaction = posts.find(p => p.post_id === postId)?.user_reaction;
      
      if ((isMainButton && currentReaction) || 
          (!isMainButton && currentReaction?.toLowerCase() === reactionType.toLowerCase())) {
        const response = await fetch(`${BASE_URL}/api/feed/reactions/${postId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${getToken()}`
          }
        });

        if (response.ok) {
          await fetchFeedPosts();
          setShowReactions(null);
          return;
        }
      }

      const response = await fetch(`${BASE_URL}/api/feed/reactions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          post_id: postId,
          reaction_type: isMainButton && !currentReaction ? 'like' : reactionType.toLowerCase()
        })
      });

      if (response.ok) {
        await fetchFeedPosts();
      }
    } catch (error) {
      console.error('Error updating reaction:', error);
    } finally {
      // Clear loading state for specific post
      setSubmittingReactions(prev => ({
        ...prev,
        [postId]: false
      }));
      setShowReactions(null);
    }
  };

  const renderReactionCounts = (postReactions) => {
    if (!postReactions || Object.keys(postReactions).length === 0) return null;

    const totalReactions = Object.values(postReactions).reduce((sum, reaction) => 
      sum + reaction.count, 0
    );

    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 0.5,
        color: '#999',
        fontSize: '0.85rem',
        mb: 1
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: -0.5 
        }}>
          {Object.entries(postReactions).slice(0, 3).map(([type], index) => (
            <Box
              key={type}
              sx={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                bgcolor: '#1E1E1E',
                border: '2px solid #1E1E1E',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 3 - index,
                '& svg': { fontSize: '14px' }
              }}
            >
              {getReactionIcon(type)}
            </Box>
          ))}
        </Box>
        <Typography sx={{ fontSize: 'inherit', ml: 1 }}>
          {totalReactions}
        </Typography>
      </Box>
    );
  };

  const handleCommentUpdate = async (postId) => {
    // Update the specific post's comment count
    setPosts(prevPosts => 
      prevPosts.map(post => 
        post.post_id === postId 
          ? { ...post, comment_count: (post.comment_count || 0) + 1 }
          : post
      )
    );
  };

  const renderPostContent = (post) => {
    const userReaction = post.user_reaction;
    const currentUser = JSON.parse(atob(getToken().split('.')[1]));
    const isSuperAdmin = currentUser.role_id === 1;
    
    return (
      <Card sx={{ 
        mb: 2,
        borderRadius: 2,
        bgcolor: '#1E1E1E',
        boxShadow: 'none',
        border: '1px solid rgba(255,255,255,0.1)'
      }}>
        {/* Header Section */}
        <CardContent sx={{ pb: 1, pt: 1.5, px: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 1
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Avatar sx={{ 
                bgcolor: getAvatarColor(post.who_created),
                width: 36,
                height: 36,
                fontWeight: 600,
                fontSize: '0.9rem',
                border: `2px solid ${getAvatarColor(post.who_created)}40`
              }}>
                {post.creator_first_name ? post.creator_first_name[0] : 'A'}
              </Avatar>
              <Box>
                <Typography sx={{ 
                  color: '#fff',
                  fontWeight: 600,
                  fontSize: '0.9rem',
                  lineHeight: 1.2
                }}>
                  {post.creator_first_name} {post.creator_last_name}
                </Typography>
                <Typography sx={{ 
                  color: '#999',
                  fontSize: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5
                }}>
                  {format(new Date(post.date_created), 'MMM dd, yyyy')}
                  {post.post_type !== 'general_post' && (
                    <>
                      <span style={{ color: '#666' }}>•</span>
                      <span style={{ 
                        color: '#7B4FFF',
                        fontSize: '0.7rem',
                        fontWeight: 500
                      }}>
                        {post.post_type === 'course_notification' ? 'Course Update' : 'Video Update'}
                      </span>
                    </>
                  )}
                  {isSuperAdmin && (
                    <>
                      <span style={{ color: '#666' }}>•</span>
                      <span style={{ 
                        color: '#FF6B6B',  // Different color to distinguish client info
                        fontSize: '0.7rem',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5
                      }}>
                        <BusinessIcon sx={{ fontSize: '0.9rem' }} />
                        {post.client_name}
                      </span>
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Content Section */}
          <Box>
            <Typography variant="h6" sx={{ 
              color: '#fff',
              mb: 0.5,
              fontSize: '1rem',
              fontWeight: 600,
              lineHeight: 1.3
            }}>
              {post.title}
            </Typography>
            <Typography sx={{ 
              color: 'rgba(255,255,255,0.8)',
              fontSize: '0.85rem',
              lineHeight: 1.4,
              mb: 1
            }}>
              {post.content}
            </Typography>

            {/* Associated Content Badge */}
            {(post.course_name || post.video_title) && (
              <Box sx={{ 
                display: 'inline-flex',
                alignItems: 'center',
                gap: 1,
                py: 0.25,
                px: 1,
                bgcolor: 'primary.main',
                borderRadius: 1.5,
                border: '1px solid rgba(123,79,255,0.2)',
                mb: 1
              }}>
                <Typography sx={{ 
                  color: '#fff',
                  fontSize: '0.75rem',
                  fontWeight: 500
                }}>
                  {post.post_type === 'course_notification' ? 
                    `📚 ${post.course_name}` : 
                    `🎥 ${post.video_title}`}
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>

        {/* Image Section */}
        {post.image_url && (
          <Box sx={{ px: 2, pb: 1.5 }}>
            <Box sx={{ 
              position: 'relative',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: '0 4px 14px rgba(0,0,0,0.15)',
              cursor: 'pointer'
            }}>
              <CardMedia
                component="img"
                image={`${BASE_URL}${post.image_url}`}
                alt={post.title}
                onClick={() => setPreviewImage(post.image_url)}
                sx={{ 
                  height: 220,
                  objectFit: 'cover'
                }}
              />
            </Box>
          </Box>
        )}

        {/* Reactions and Comments Section */}
        <Box sx={{ 
          px: 2, 
          py: 1,
          position: 'relative'
        }}>
          {/* Reaction Counts */}
          <Box sx={{ pl: 0.5 }}>
            {renderReactionCounts(post.reactions)}
          </Box>

          {/* Actions Bar */}
          <Box 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              position: 'relative',
              borderTop: '1px solid rgba(255,255,255,0.1)',
              mt: 1,
              pt: 1
            }}
          >
            {/* Reactions Section */}
            <Box sx={{ 
              position: 'relative',
              display: 'flex' 
            }}>
              {/* Main Reaction Button */}
              <Button
                size="small"
                startIcon={userReaction ? 
                  getReactionIcon(userReaction) : 
                  reactions[0].icon
                }
                onMouseEnter={() => setShowReactions(post.post_id)}
                onMouseLeave={() => setShowReactions(null)}
                onClick={() => handleReaction(post.post_id, userReaction || 'like', true)}
                disabled={submittingReactions[post.post_id]}
                sx={{ 
                  color: userReaction ? 
                    getReactionColor(userReaction) : 
                    '#999',
                  textTransform: 'none',
                  py: 0.5,
                  pl: 0.5,
                  minWidth: 'auto',
                  position: 'relative',
                  zIndex: 1,
                  '&:hover': {
                    color: userReaction ? 
                      getReactionColor(userReaction) : 
                      reactions[0].color,
                    bgcolor: userReaction ? 
                      `${getReactionColor(userReaction)}15` : 
                      `${reactions[0].color}15`
                  }
                }}
              >
                {userReaction ? 
                  reactions.find(r => 
                    r.label.toLowerCase() === userReaction.toLowerCase()
                  )?.label : 
                  'Like'
                }
              </Button>

              {/* Reactions Popup */}
              {showReactions === post.post_id && (
                <Box
                  onMouseEnter={() => setShowReactions(post.post_id)}
                  onMouseLeave={() => setShowReactions(null)}
                  sx={{
                    position: 'absolute',
                    bottom: '100%',
                    left: 0,
                    display: 'flex',
                    gap: 0,
                    bgcolor: '#1E1E1E',
                    p: 1,
                    borderRadius: 5,
                    border: '1px solid rgba(255,255,255,0.1)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                    zIndex: 10,
                    transform: 'translateY(-10%) translateX(-12px)',
                    overflow: 'visible',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -20,
                      left: 0,
                      right: 0,
                      height: 20,
                      background: 'transparent',
                      zIndex: 9
                    }
                  }}
                >
                  {reactions.map((reaction) => (
                    <IconButton
                      key={reaction.label}
                      size="small"
                      disabled={submittingReactions[post.post_id]}
                      onClick={() => handleReaction(post.post_id, reaction.label, false)}
                      sx={{
                        color: reaction.color,
                        p: 1,
                        mx: 0.5,
                        zIndex: 11,
                        position: 'relative',
                        transformOrigin: 'center center',
                        '&:hover': {
                          bgcolor: `${reaction.color}15`,
                          transform: 'scale(1.15)',
                          transition: 'all 0.2s ease'
                        },
                        transition: 'all 0.2s ease',
                        ...(userReaction?.toLowerCase() === reaction.label.toLowerCase() && {
                          bgcolor: `${reaction.color}15`,
                        })
                      }}
                    >
                      {reaction.icon}
                    </IconButton>
                  ))}
                </Box>
              )}
            </Box>

            {/* Comment Button */}
            <Button
              size="small"
              startIcon={<ChatBubbleOutlineIcon />}
              onClick={() => {
                setShowComments(prev => ({
                  ...prev,
                  [post.post_id]: !prev[post.post_id]
                }));
              }}
              sx={{ 
                color: '#999',
                textTransform: 'none',
                py: 0.5,
                pl: 0.5,
                minWidth: 'auto',
                '&:hover': {
                  color: '#7B4FFF',
                  bgcolor: 'rgba(123,79,255,0.1)'
                }
              }}
            >
              {post.comment_count > 0 ? 
                `${post.comment_count} ${post.comment_count === 1 ? 'Comment' : 'Comments'}` : 
                'Comment'
              }
            </Button>
          </Box>
        </Box>

        {/* Comments Section */}
        {showComments[post.post_id] && (
          <Box sx={{ px: 2, pb: 2 }}>
            <Comments 
              postId={post.post_id} 
              currentUser={currentUser}
              onCommentUpdate={handleCommentUpdate}
            />
          </Box>
        )}
      </Card>
    );
  };

  // Helper function to get reaction icon
  const getReactionIcon = (reactionType) => {
    if (!reactionType) return reactions[0].icon;
    const reaction = reactions.find(r => 
      r.label.toLowerCase() === reactionType.toLowerCase()
    );
    return reaction ? reaction.icon : reactions[0].icon;
  };

  // Helper function to get reaction color
  const getReactionColor = (reactionType) => {
    if (!reactionType) return '#999';
    const reaction = reactions.find(r => 
      r.label.toLowerCase() === reactionType.toLowerCase()
    );
    return reaction ? reaction.color : '#999';
  };

  // Compact create post card
  const renderCreatePostCard = () => (
    <Card sx={{ 
      mb: 3, 
      borderRadius: 2,
      bgcolor: '#1E1E1E',
      boxShadow: 'none',
      maxWidth: 800,
      width: '100%',
      mx: 'auto'
    }}>
      <CardContent sx={{ 
        p: '16px !important',
        display: 'flex',
        width: '100%'
      }}>
        <Box 
          onClick={() => setOpenDialog(true)}
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 2,
            cursor: 'pointer',
            width: '100%',
            flexGrow: 1
          }}
        >
          <Avatar sx={{ 
            bgcolor: '#7B4FFF',
            width: 40,
            height: 40,
            flexShrink: 0
          }}>A</Avatar>
          <TextField
            placeholder="Start a post"
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            sx={{ 
              flexGrow: 1,
              '& .MuiOutlinedInput-root': {
                bgcolor: '#333',
                borderRadius: 1.5,
                cursor: 'pointer',
                '& fieldset': { border: 'none' },
                '& input': {
                  color: '#999',
                  cursor: 'pointer',
                  '&::placeholder': {
                    color: '#999',
                    opacity: 1
                  }
                }
              }
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );

  // Handle image file change
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  // Create post dialog
  const renderCreatePostDialog = () => (
    <Dialog 
      open={openDialog} 
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: '#1E1E1E',
          borderRadius: 2,
          minHeight: '60vh',
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          width: { sm: '600px' },
          position: 'absolute',
          left: '50%',
          transform: { 
            sm: open ? 
              'translateX(calc(-50% + 80px))' : // 240px/2 = 120px when expanded
              'translateX(calc(-57%))'    // 72px/2 = 36px when collapsed
          }
        }
      }}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'center',
          justifyContent: 'center'
        }
      }}
    >
      <DialogContent sx={{ p: 0, bgcolor: '#1E1E1E', flex: 1, overflow: 'auto' }}>
        {/* Header Section */}
        <Box sx={{ 
          p: 2,
          position: 'sticky',
          top: 0,
          bgcolor: '#1E1E1E',
          zIndex: 1
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Avatar sx={{ 
                bgcolor: '#7B4FFF',
                width: 44,
                height: 44
              }}>A</Avatar>
              <Typography sx={{ 
                color: '#fff', 
                fontSize: '1.1rem',
                fontWeight: 500
              }}>
                Create a post
              </Typography>
            </Box>
            <IconButton 
              onClick={handleCloseDialog}
              sx={{ color: '#999' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Content Section */}
        <Box sx={{ 
          p: 2, 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '400px'
        }}>
          <TextField
            placeholder="Post title"
            fullWidth
            value={newPost.title}
            onChange={(e) => setNewPost({ ...newPost, title: e.target.value })}
            variant="standard"
            sx={{ 
              mb: 2,
              '& .MuiInput-root': {
                color: '#fff',
                fontSize: '1.25rem',
                '&:before, &:after': { display: 'none' },
                '& input::placeholder': {
                  color: '#666',
                  opacity: 1
                }
              }
            }}
          />

          <TextField
            placeholder="What do you want to share?"
            fullWidth
            multiline
            rows={4}
            value={newPost.content}
            onChange={(e) => setNewPost({ ...newPost, content: e.target.value })}
            variant="standard"
            sx={{ 
              '& .MuiInput-root': {
                color: '#fff',
                '&:before, &:after': { display: 'none' },
                '& textarea::placeholder': {
                  color: '#666',
                  opacity: 1
                }
              }
            }}
          />

          {/* Spacer to push image upload to bottom */}
          <Box sx={{ flex: 1 }} />

          {/* Divider */}
          <Divider sx={{ 
            my: 2, 
            borderColor: 'rgba(255,255,255,0.1)' 
          }} />

          {/* Image Upload with Icon */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            justifyContent: 'flex-end'
          }}>
            {imageFile && (
              <Typography sx={{ 
                color: '#999',
                fontSize: '0.9rem'
              }}>
                {imageFile.name}
              </Typography>
            )}
            <IconButton
              component="label"
              sx={{ 
                color: '#7B4FFF',
                '&:hover': { 
                  bgcolor: 'rgba(123,79,255,0.1)' 
                }
              }}
            >
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />
              <ImageIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>

      {/* Footer Section */}
      <Box sx={{ 
        p: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        bgcolor: '#1E1E1E'
      }}>
        <Button 
          onClick={handleCloseDialog}
          sx={{ 
            borderRadius: 2,
            px: 3,
            color: '#fff',
            bgcolor: 'rgba(255,255,255,0.1)',
            '&:hover': {
              bgcolor: 'rgba(255,255,255,0.2)'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={submitting || !newPost.title || !newPost.content}
          onClick={async () => {
            const formData = new FormData();
            formData.append('title', newPost.title);
            formData.append('content', newPost.content);
            formData.append('post_type', 'general_post');
            formData.append('is_public', true);
            if (imageFile) {
              formData.append('image', imageFile);
            }
            await handleCreatePost(formData);
            handleCloseDialog();
          }}
          sx={{ 
            borderRadius: 2,
            px: 3,
            bgcolor: '#7B4FFF',
            '&:hover': {
              bgcolor: '#6B3FEF'
            },
            '&.Mui-disabled': {
              bgcolor: 'rgba(255,255,255,0.1)',
              color: '#666'
            }
          }}
        >
          {submitting ? <CircularProgress size={20} /> : 'Post'}
        </Button>
      </Box>
    </Dialog>
  );

  // Add image preview dialog component
  const ImagePreviewDialog = () => (
    <Dialog 
      open={!!previewImage} 
      onClose={() => setPreviewImage(null)}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden'
        }
      }}
    >
      <IconButton
        onClick={() => setPreviewImage(null)}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: '#fff',
          bgcolor: 'rgba(0,0,0,0.5)',
          '&:hover': {
            bgcolor: 'rgba(0,0,0,0.7)'
          },
          zIndex: 1
        }}
      >
        <CloseIcon />
      </IconButton>
      <img 
        src={`${BASE_URL}${previewImage}`}
        alt="Preview"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          maxHeight: '90vh'
        }}
      />
    </Dialog>
  );

  // Add fetchCurrentUser function
  const fetchCurrentUser = async () => {
    try {
      const token = getToken();
      const payload = JSON.parse(atob(token.split('.')[1]));
      setCurrentUser(payload);
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  // Add this new component for loading state
  const LoadingSkeleton = () => (
    <Card sx={{ 
      mb: 2,
      borderRadius: 2,
      bgcolor: '#1E1E1E',
      boxShadow: 'none',
      border: '1px solid rgba(255,255,255,0.1)'
    }}>
      <CardContent>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Skeleton 
            variant="circular" 
            width={36} 
            height={36} 
            sx={{ bgcolor: 'rgba(255,255,255,0.1)' }}
          />
          <Box sx={{ ml: 2 }}>
            <Skeleton 
              variant="text" 
              width={120} 
              sx={{ 
                bgcolor: 'rgba(255,255,255,0.1)',
                fontSize: '0.9rem'
              }} 
            />
            <Skeleton 
              variant="text" 
              width={80} 
              sx={{ 
                bgcolor: 'rgba(255,255,255,0.1)',
                fontSize: '0.75rem'
              }} 
            />
          </Box>
        </Box>

        {/* Content */}
        <Skeleton 
          variant="text" 
          width="40%" 
          sx={{ 
            bgcolor: 'rgba(255,255,255,0.1)',
            fontSize: '1rem',
            mb: 1
          }} 
        />
        <Skeleton 
          variant="text" 
          width="90%" 
          sx={{ 
            bgcolor: 'rgba(255,255,255,0.1)',
            fontSize: '0.85rem'
          }} 
        />
        <Skeleton 
          variant="text" 
          width="80%" 
          sx={{ 
            bgcolor: 'rgba(255,255,255,0.1)',
            fontSize: '0.85rem'
          }} 
        />

        {/* Actions */}
        <Box sx={{ 
          mt: 2, 
          pt: 2, 
          borderTop: '1px solid rgba(255,255,255,0.1)',
          display: 'flex',
          gap: 2
        }}>
          <Skeleton 
            variant="rounded" 
            width={80} 
            height={32} 
            sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} 
          />
          <Skeleton 
            variant="rounded" 
            width={80} 
            height={32} 
            sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} 
          />
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: 800, 
      mx: 'auto', 
      p: 3 
    }}>
      {/* Create Post Card - Always visible */}
      <Box sx={{ 
        width: '100%',
        mb: 3 // Add margin to separate from feed content
      }}>
        {renderCreatePostCard()}
      </Box>

      {renderCreatePostDialog()}
      <ImagePreviewDialog />
      
      {/* Posts List - Loading State */}
      {posts.length > 0 ? (
        <>
          {posts.map((post) => (
            <Box key={post.post_id} sx={{ mb: 3 }}>
              {renderPostContent(post)}
            </Box>
          ))}
          
          {hasMore && (
            <Box ref={ref} sx={{ my: 4 }}>
              <LoadingSkeleton />
            </Box>
          )}
        </>
      ) : loading ? (
        // Show multiple skeletons while loading
        <Box>
          {[1, 2, 3].map((i) => (
            <LoadingSkeleton key={i} />
          ))}
        </Box>
      ) : error ? (
        <Box sx={{ p: 3 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <Typography variant="body1" textAlign="center" color="text.secondary">
          No feed posts available
        </Typography>
      )}
    </Box>
  );
}

export default Feed; 