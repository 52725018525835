import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Select from'@mui/material/Select';
import MenuItem from'@mui/material/MenuItem';
import InputLabel from'@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';  
import Typography from '@mui/material/Typography';
import { BASE_URL } from '../../Constants';
import { getToken, getUserId, getRoleId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { theme } from '../../theme';

const ContentWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '2rem',
  maxWidth: '800px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const StyledPaper = styled(Paper)({
  padding: '2rem',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  width: '100%',
  maxWidth: '500px'
});

const FormSection = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1.5rem',
  marginBottom: '1.5rem',
  '@media (max-width: 600px)': {
    gridTemplateColumns: '1fr',
  }
});

function AddUser() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState('student');
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [openNewClientDialog, setOpenNewClientDialog] = useState(false);
  const [newClientName, setNewClientName] = useState('');
  const roleId = getRoleId();

  const navigate = useNavigate();
  const token = getToken(); 
  const userId = getUserId(); 

  useEffect(() => {
    if (roleId === 1) {
      fetch(`${BASE_URL}/api/readclients`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setClients(data);
          if (data.length > 0) {
            setSelectedClient(data[0].client_id);
          }
        } else {
          console.error('Expected array of clients but got:', data);
          setClients([]);
        }
      })
      .catch(error => {
        console.error('Error fetching clients:', error);
        setClients([]);
      });
    }
  }, [roleId, token]);

  // Email validation utility function
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isValidEmail(email)) {
        toast.error('Invalid Email', {
          autoClose: 3000,  
          position: toast.POSITION.TOP_CENTER,
        });
        return;
    }  

    const formData = {
      firstName,
      lastName,
      email,
      role_id: parseInt(role),
      userId: userId,
      client_id: roleId === 1 ? selectedClient : undefined
    };

    fetch(`${BASE_URL}/api/addadminorstudent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization':   `Bearer ${token}`
      },
      body: JSON.stringify(formData),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to add user');
      }
    })
    .then((data) => {
      console.log('Success:', data);
      // Reset all form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setRole("student");
      toast.success('Success!', {
          autoClose: 2000,
          position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error) => {
      console.error('Error:', error);
    });

    //add email to allow list?
  };

  const handleClientChange = (event) => {
    const value = event.target.value;
    if (value === 'new') {
      setOpenNewClientDialog(true);
    } else {
      setSelectedClient(value);
    }
  };

  const handleCreateClient = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/addclient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ clientName: newClientName })
      });

      if (!response.ok) {
        throw new Error('Failed to create client');
      }

      const data = await response.json();
      
      // Add new client to the list and select it
      setClients(prevClients => [...prevClients, data.client]);
      setSelectedClient(data.client.client_id);
      
      // Close dialog and reset input
      setOpenNewClientDialog(false);
      setNewClientName('');
      
      toast.success('Client created successfully!', {
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.error('Error creating client:', error);
      toast.error('Failed to create client', {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      // Reset selection to previous value if there's an error
      if (selectedClient === 'new') {
        setSelectedClient(clients[0]?.client_id || '');
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        height: '100vh',
        width: '100vw',
        bgcolor: 'background.default',
        position: 'relative'
      }}>
        <CssBaseline />
        <ContentWrapper>
          <Typography 
            variant="h4" 
            component="h1" 
            gutterBottom 
            sx={{ 
              mb: 4, 
              textAlign: 'center',
              fontWeight: 600,
              color: 'primary.main'
            }}
          >
            Add New User
          </Typography>

          <StyledPaper>
            <FormSection>
              {/* First Name and Last Name in same row */}
              <TextField
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                variant="outlined"
              />
              <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </FormSection>

            <FormSection>
              {/* Email and Role in same row */}
              <TextField
                label="Email"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                variant="outlined"
              />
              <FormControl fullWidth variant="outlined">
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  value={role}
                  label="Role"
                  onChange={(e) => setRole(e.target.value)}
                >
                  {roleId === 1 && (
                    <MenuItem value={1}>Super Admin</MenuItem>
                  )}
                  <MenuItem value={2}>Admin</MenuItem>
                  <MenuItem value={3}>User</MenuItem>
                </Select>
              </FormControl>
            </FormSection>

            {/* Client Selection for superadmins */}
            {roleId === 1 && (
              <FormSection sx={{ gridTemplateColumns: '1fr' }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="client-label">Client</InputLabel>
                  <Select
                    labelId="client-label"
                    id="client"
                    value={selectedClient}
                    label="Client"
                    onChange={handleClientChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: '400px'
                        },
                      },
                      sx: {
                        zIndex: 9999          // Ensure dropdown appears above other elements
                      }
                    }}
                  >
                    {Array.isArray(clients) && clients.map(client => (
                      <MenuItem key={client.client_id} value={client.client_id}>
                        {client.client_name}
                      </MenuItem>
                    ))}
                    <MenuItem value="new" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                      + Add New Client
                    </MenuItem>
                  </Select>
                </FormControl>
              </FormSection>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSubmit}
                size="large"
                sx={{
                  minWidth: '200px',
                  height: '48px',
                  borderRadius: '24px'
                }}
              >
                Create User
              </Button>
            </Box>
          </StyledPaper>

          {/* Dialog remains unchanged */}
          <Dialog open={openNewClientDialog} onClose={() => {
            setOpenNewClientDialog(false);
            setNewClientName('');
            // Reset selection to previous value if dialog is closed
            if (selectedClient === 'new') {
              setSelectedClient(clients[0]?.client_id || '');
            }
          }}>
            <DialogTitle>Add New Client</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Client Name"
                fullWidth
                value={newClientName}
                onChange={(e) => setNewClientName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setOpenNewClientDialog(false);
                setNewClientName('');
                // Reset selection to previous value if dialog is closed
                if (selectedClient === 'new') {
                  setSelectedClient(clients[0]?.client_id || '');
                }
              }}>
                Cancel
              </Button>
              <Button 
                onClick={handleCreateClient}
                disabled={!newClientName.trim()}
                variant="contained"
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </ContentWrapper>
      </Box>
    </ThemeProvider>
  );
}

export default AddUser;