import jwt_decode from 'jwt-decode';

export function getToken() {
  return sessionStorage.getItem('token') || localStorage.getItem('token');
}

export function getUserId() {
  const token = getToken();
  if (!token) return null;
  try {
    const decodedToken = jwt_decode(token);
    return decodedToken.user_id;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}

export function getFarmId() {
  const token = getToken();
  if (!token) return null;
  try {
    const decodedToken = jwt_decode(token);
    return decodedToken.farm_id;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}

export function verifyToken() {
  const token = getToken();
  if (!token) {
    return false;
  }

  try {
    const decoded = jwt_decode(token);
    // Check if the token has expired
    if (decoded.exp < Date.now() / 1000) {
      return false;
    }
    return true;
  } catch (err) {
    console.error('Error verifying token:', err);
    return false;
  }
}

export function getRoleId() {
  const token = getToken();
  if (!token) return null;
  try {
    const decodedToken = jwt_decode(token);
    return decodedToken.role_id;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}

export function getClientId() {
  const token = getToken();
  if (!token) return null;
  try {
    const decodedToken = jwt_decode(token);
    return decodedToken.client_id;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}

export function isSuperAdmin() {
  return getRoleId() === 1;
}

export function isCompanyAdmin() {
  return getRoleId() === 2;
}