import React, { useState, useEffect, useRef } from 'react';
import { 
    Box, Typography, Paper, TextField, Button, 
    List, ListItem, ListItemText, CircularProgress,
    IconButton
} from '@mui/material';
import { getToken } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MicIcon from '@mui/icons-material/Mic';

function ChatHistoryNavbar({ chatHistory, onThreadSelect, currentThreadId }) {
    const nonEmptyThreads = chatHistory.filter(thread => thread.messageCount > 0);

    return (
        <Box sx={{ 
            maxHeight: '200px',
            overflowY: 'auto', 
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            borderRadius: '8px',
        }}>
            <List sx={{ 
                width: '100%', 
                bgcolor: 'background.paper',
                '& .MuiListItem-root': {
                    borderRadius: '8px',
                    mb: 0.5,
                    transition: 'background-color 0.2s ease',
                }
            }}>
                {nonEmptyThreads.map((thread) => (
                    <ListItem 
                        key={thread.threadId}
                        button 
                        onClick={() => onThreadSelect(thread.threadId)}
                        selected={thread.threadId === currentThreadId}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: 'action.selected',
                                '&:hover': {
                                    backgroundColor: 'action.selected',
                                }
                            },
                            margin: '8px',
                            width: 'calc(100% - 16px)',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            }
                        }}
                    >
                        <ListItemText 
                            primary={`Chat ${new Date(thread.createdAt).toLocaleDateString()}`}
                            secondary={`${thread.lastMessage} (${thread.messageCount} messages)`}
                            sx={{
                                '& .MuiListItemText-primary': {
                                    fontWeight: thread.threadId === currentThreadId ? 500 : 400,
                                },
                                '& .MuiListItemText-secondary': {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    opacity: 0.7
                                }
                            }}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

function AIChat({ 
    courseId, 
    courseName, 
    currentVideo, 
    videos,
    isOracleCourse,
    courseDescription,
    courseType,
    courseThumbnail,
    courseDocuments,
    isMobile,
    isOpen,
    onClose
}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [lmsThreadId, setLMSThreadId] = useState(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [showChatHistory, setShowChatHistory] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const messagesEndRef = useRef(null);
    const token = getToken();

    useEffect(() => {
        if (!courseId) return;
        loadThreadAndMessages();
        fetchChatHistory();
    }, [courseId]);

    const loadThreadAndMessages = async () => {
        try {
            const threadId = await createThread(courseName);
            if (threadId) {
                setLMSThreadId(threadId);
                await fetchMessages(threadId);
            }
        } catch (error) {
            console.error('Error loading thread and messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const createThread = async (course_name) => {
        try {
            const response = await fetch(`${BASE_URL}/api/createLMSthread`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ course_name })
            });
            
            if (!response.ok) throw new Error('Failed to create thread');
            const data = await response.json();
            return data.threadId;
        } catch (error) {
            console.error('Error creating thread:', error);
            return null;
        }
    };

    const fetchMessages = async (threadId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSmessages/${threadId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            
            if (!response.ok) throw new Error('Failed to fetch messages');
            const data = await response.json();
            setMessages(data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const fetchChatHistory = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSChatHistory?course_name=${courseName}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) throw new Error('Failed to fetch chat history');
            const data = await response.json();
            setChatHistory(data.chatHistory);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const handleThreadSelect = async (threadId) => {
        setLMSThreadId(threadId);
        setIsLoadingMessages(true);
        try {
            await fetchMessages(threadId);
        } catch (error) {
            console.error('Error loading messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const sendMessage = async () => {
        if (!inputMessage.trim() || !lmsThreadId) return;

        const newMessage = { role: 'user', content: inputMessage };
        setMessages(prev => [...prev, newMessage]);
        setInputMessage('');
        setIsLoading(true);

        const placeholderId = `placeholder-${Date.now()}`;
        let isStreaming = false;
        
        try {
            setMessages(prev => [...prev, { 
                role: 'assistant', 
                content: '', 
                id: placeholderId,
                isLoading: true
            }]);

            const response = await fetch(`${BASE_URL}/api/sendLMSchatmessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    threadId: lmsThreadId,
                    message: inputMessage,
                    course_name: courseName,
                    course_id: courseId,
                    video_id: currentVideo?.id
                }),
            });

            const reader = response.body.getReader();
            let accumulatedContent = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = new TextDecoder().decode(value);
                const lines = chunk.split('\n');

                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        try {
                            const data = JSON.parse(line.slice(6));

                            switch (data.type) {
                                case 'chunk':
                                    if (!isStreaming) {
                                        isStreaming = true;
                                        setIsLoading(false);
                                        setMessages(prev => prev.map(msg => 
                                            msg.id === placeholderId 
                                                ? { ...msg, content: '', isLoading: false }
                                                : msg
                                        ));
                                    }
                                    accumulatedContent += data.content;
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { ...msg, content: accumulatedContent }
                                            : msg
                                    ));
                                    break;

                                case 'error':
                                    console.error('Stream error:', data.error);
                                    throw new Error(data.error);

                                case 'end':
                                    const finalContent = data.content || accumulatedContent;
                                    setMessages(prev => prev.map(msg => 
                                        msg.id === placeholderId 
                                            ? { role: 'assistant', content: finalContent }
                                            : msg
                                    ));
                                    messagesEndRef.current?.scrollIntoView({ block: 'end' });
                                    await fetchChatHistory();
                                    return;

                                case 'tool_start':
                                case 'tool_input':
                                case 'tool_output':
                                    break;
                            }
                        } catch (e) {
                            console.error('Error parsing SSE data:', e);
                            throw e;
                        }
                    }
                }
            }

            await fetchChatHistory();
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => prev.filter(msg => msg.id !== placeholderId));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage?.role === 'user') {
            messagesEndRef.current?.scrollIntoView({ block: 'end' });
        }
    }, [messages]);

    const formatBoldText = (text) => {
        const parts = text.split(/(\*\*.*?\*\*)/g);
        return parts.map((part, i) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return (
                    <Typography
                        key={i}
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {part.slice(2, -2)}
                    </Typography>
                );
            }
            return <span key={i}>{part}</span>;
        });
    };

    const handleTimestampClick = (timestamp, videoId) => {
        const video = videos?.find(v => v.id === parseInt(videoId));
        if (video) {
            const parts = timestamp.split(':');
            let seconds = 0;
            
            if (parts.length === 3) {
                seconds = parseInt(parts[0]) * 3600 + 
                         parseInt(parts[1]) * 60 + 
                         parseFloat(parts[2]);
            } else if (parts.length === 2) {
                seconds = parseInt(parts[0]) * 60 + 
                         parseFloat(parts[1]);
            } else {
                seconds = parseFloat(parts[0]);
            }
            
            navigate('/video-player', {
                state: {
                    video,
                    courseId,
                    courseName,
                    videos,
                    startTime: seconds,
                    preserveChat: true,
                    courseDescription,
                    courseType,
                    courseThumbnail,
                    courseDocuments,
                    isOracleCourse,
                    chatState: {
                        messages,
                        lmsThreadId,
                        chatHistory
                    }
                }
            });
        }
    };

    const formatTimestamps = (text) => {
        // First handle course document references
        const courseDocRegex = /\[([^\]]+?) - Course Document\]/g;
        let processedText = text.replace(courseDocRegex, (match, filename) => {
            return `[${filename}](${BASE_URL}/uploads/course-docs/${filename})`;
        });

        // Then handle existing file references (video documents)
        const fileReferenceRegex = /\[([^\]]+?) - Video (\d+)\]/g;
        let lastFileRef = null;
        
        processedText = processedText.replace(fileReferenceRegex, (match, filename, videoId) => {
            const currentFileRef = `${filename}-${videoId}`;
            
            if (currentFileRef === lastFileRef) {
                return '';
            }
            
            lastFileRef = currentFileRef;
            
            const targetVideo = videos?.find(v => v.id === parseInt(videoId));
            if (targetVideo?.supportingDocuments) {
                const document = targetVideo.supportingDocuments.find(doc => 
                    doc.name.includes(filename) || doc.path.includes(filename)
                );
                if (document) {
                    return `[${document.name}](${BASE_URL}/${document.path})`;
                }
            }
            return match;
        });

        // Rest of the existing timestamp processing
        processedText = processedText.replace(/\s*\.\s*(?=\n|$)/g, '');
        const timestampRegex = /(?:\(|\[|【)(\d{1,2}:\d{2}(?::\d{2})?(?:\.\d{1,3})?)(?: - )?([^)\]】]+)?(?:\)|\]|】)/g;
        const parts = processedText.split(timestampRegex);
        
        return parts.map((part, i) => {
            if (i % 3 === 1) {
                const timestamp = part;
                const videoTitle = parts[i + 1];
                
                const video = videoTitle ? 
                    videos?.find(v => v.title.toLowerCase().includes(videoTitle.toLowerCase())) : 
                    currentVideo;

                return (
                    <Button
                        key={i}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleTimestampClick(timestamp, video?.id || currentVideo?.id);
                        }}
                        sx={{
                            color: 'primary.main',
                            textTransform: 'none',
                            p: '0 4px',
                            minWidth: 'auto',
                            fontWeight: 'inherit',
                            fontSize: 'inherit',
                            '&:hover': {
                                backgroundColor: 'action.hover'
                            }
                        }}
                    >
                        {videoTitle ? 
                            `(${timestamp} - ${videoTitle})` :
                            `(${timestamp})`
                        }
                    </Button>
                );
            } else if (i % 3 === 2) {
                return null;
            }
            
            const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
            const linkParts = part.split(linkRegex);
            
            return linkParts.map((linkPart, j) => {
                if (j % 3 === 1) {
                    return (
                        <Button
                            key={`link-${j}`}
                            component="a"
                            href={linkParts[j + 1]}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: 'primary.main',
                                textTransform: 'none',
                                p: '0 4px',
                                minWidth: 'auto',
                                fontWeight: 'inherit',
                                fontSize: 'inherit',
                                '&:hover': {
                                    backgroundColor: 'action.hover'
                                }
                            }}
                        >
                            {linkPart}
                        </Button>
                    );
                } else if (j % 3 === 2) {
                    return null;
                }
                return <span key={`text-${j}`}>{formatBoldText(linkPart)}</span>;
            });
        });
    };

    const formatContent = (content) => {
        return content.split('\n').map((paragraph, i) => {
            const numberMatch = paragraph.match(/^(\d+\.\s*)(.*)/);
            
            if (numberMatch) {
                return (
                    <Box key={i} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                        <Typography component="span" sx={{ color: 'primary.main', fontWeight: 'bold', flexShrink: 0 }}>
                            {numberMatch[1]}
                        </Typography>
                        <Typography component="span" sx={{ wordBreak: 'break-word' }}>
                            {formatTimestamps(numberMatch[2])}
                        </Typography>
                    </Box>
                );
            }
            
            return (
                <Typography 
                    key={i} 
                    variant="body1" 
                    paragraph={true}
                    sx={{ 
                        mb: 1,
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word'
                    }}
                >
                    {formatTimestamps(paragraph)}
                </Typography>
            );
        });
    };

    const renderMessage = (msg, index) => {
        return (
            <Box 
                key={index} 
                sx={{ 
                    mb: 2, 
                    p: 2, 
                    backgroundColor: msg.role === 'user' 
                        ? theme.palette.action.selected
                        : theme.palette.background.paper,
                    borderRadius: 2,
                    maxWidth: '85%',
                    width: 'fit-content',
                    alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                }}
            >
                <Typography 
                    variant="subtitle2" 
                    sx={{ 
                        mb: 0.5,
                        color: msg.role === 'user' ? 'primary.main' : 'secondary.main',
                        fontWeight: 'bold'
                    }}
                >
                    {msg.role === 'user' ? 'You' : 'SAGE'}
                </Typography>
                {msg.isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress size={20} />
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Thinking...
                        </Typography>
                    </Box>
                ) : (
                    formatContent(msg.content)
                )}
            </Box>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.scrollTo = function() {};
        sendMessage();
    };

    const startNewChat = async () => {
        setIsLoadingMessages(true);
        setMessages([]);
        try {
            const threadId = await createThread(courseName);
            if (threadId) {
                setLMSThreadId(threadId);
                await fetchChatHistory();
            }
        } catch (error) {
            console.error('Error starting new chat:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const startVoiceRecognition = () => {
        // Use the built-in Web Speech API if available
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            alert("Speech recognition is not supported in your browser.");
            return;
        }
        const recognition = new SpeechRecognition();
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = 'en-US';

        recognition.onstart = () => {
            setIsRecording(true);
        };

        recognition.onresult = (event) => {
            if (event.results.length > 0) {
                const transcript = event.results[0][0].transcript;
                setInputMessage((prev) => prev ? `${prev.trim()} ${transcript}` : transcript);
            }
            setIsRecording(false);
            recognition.stop();
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error", event);
            setIsRecording(false);
            recognition.stop();
        };

        recognition.onend = () => {
            setIsRecording(false);
        };

        recognition.start();
    };

    return (
        <Paper sx={{ 
            height: isMobile ? '100dvh' : '85vh',
            width: isMobile ? '100%' : '400px',
            minWidth: isMobile ? '100%' : '400px',
            display: 'flex', 
            flexDirection: 'column',
            overflow: 'hidden',
            p: 2,
            position: 'relative',
            isolation: 'isolate',
            borderRadius: isMobile ? 0 : 1,
            ...(isMobile && {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1200,
            })
        }}>
            <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                mb: 2,
                width: '100%',
                alignItems: 'center'
            }}>
                <Button 
                    onClick={() => setShowChatHistory(!showChatHistory)}
                    variant="outlined"
                    sx={{ 
                        flex: 1,
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                        color: '#FFFFFF',
                        '&:hover': {
                            borderColor: 'primary.main',
                            backgroundColor: 'rgba(255, 255, 255, 0.04)',
                        },
                        textTransform: 'none',
                        padding: '8px 16px',
                        minHeight: '40px',
                    }}
                >
                    {showChatHistory ? 'Hide Chat History' : 'Show Chat History'}
                </Button>
                <IconButton 
                    onClick={startNewChat}
                    sx={{ 
                        borderRadius: '8px',
                        backgroundColor: 'rgba(255, 255, 255, 0.04)',
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        },
                        width: '40px',
                        height: '40px',
                    }}
                >
                    <AddIcon />
                </IconButton>
                {isMobile && (
                    <IconButton 
                        onClick={onClose}
                        sx={{ 
                            color: 'text.primary',
                            ml: 1
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>

            <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                height: isMobile ? 'calc(100dvh - 72px)' : 'calc(100% - 56px)',
                overflow: 'hidden'
            }}>
                {showChatHistory && (
                    <Box sx={{ 
                        height: '200px',
                        mb: 2,
                        backgroundColor: 'background.paper',
                        borderRadius: 1,
                    }}>
                        <ChatHistoryNavbar 
                            chatHistory={chatHistory}
                            onThreadSelect={handleThreadSelect}
                            currentThreadId={lmsThreadId}
                        />
                    </Box>
                )}

                <Box sx={{ 
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: showChatHistory ? 
                        (isMobile ? 'calc(100dvh - 288px)' : 'calc(100% - 216px)') : 
                        '100%',
                    overflow: 'hidden',
                }}>
                    <Box sx={{ 
                        flexGrow: 1, 
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mb: isMobile ? 8 : 3,
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        scrollBehavior: 'smooth',
                        overscrollBehavior: 'contain',
                        p: 1,
                    }}>
                        {isLoadingMessages ? (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <CircularProgress />
                            </Box>
                        ) : messages.length === 0 && !showChatHistory ? (
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                gap: 2,
                                color: 'text.secondary',
                                textAlign: 'center',
                                p: 3
                            }}>
                                <ChatIcon sx={{ fontSize: 40, opacity: 0.7 }} />
                                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                    Start a Conversation
                                </Typography>
                                <Typography variant="body2" sx={{ maxWidth: '280px' }}>
                                    Ask questions about the course content, request clarification, or discuss specific topics.
                                </Typography>
                            </Box>
                        ) : (
                            messages.map((msg, index) => renderMessage(msg, index))
                        )}
                        <div ref={messagesEndRef} style={{ height: 0 }} />
                    </Box>

                    <Box 
                        component="form" 
                        onSubmit={handleSubmit}
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            p: 1,
                            borderRadius: 2,
                            backgroundColor: 'background.paper',
                            minHeight: '56px',
                            position: 'sticky',
                            bottom: 0,
                            zIndex: 1,
                            ...(isMobile && {
                                position: 'fixed',
                                left: '16px',
                                right: '16px',
                                bottom: '16px',
                                width: 'calc(100% - 32px)',
                                marginBottom: 0,
                                borderBottomLeftRadius: '8px',
                                borderBottomRightRadius: '8px',
                                transform: 'translateZ(0)',
                                WebkitTransform: 'translateZ(0)',
                                touchAction: 'manipulation'
                            })
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            placeholder="Type your message..."
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            inputProps={{
                                style: { fontSize: '16px' },
                                autoComplete: 'off',
                                autoCorrect: 'off',
                                autoCapitalize: 'off',
                                spellCheck: 'false'
                            }}
                            sx={{
                                '& .MuiInput-root': {
                                    padding: '8px 12px',
                                    '&:before, &:after': {
                                        display: 'none',
                                    }
                                },
                            }}
                        />

                        <IconButton 
                            onClick={startVoiceRecognition}
                            disabled={isLoading || isRecording}
                            sx={{
                                backgroundColor: isRecording ? 'error.main' : 'primary.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: isRecording ? 'error.dark' : 'primary.dark'
                                },
                                width: 40,
                                height: 40,
                            }}
                        >
                            <MicIcon />
                        </IconButton>

                        <IconButton 
                            type="submit"
                            disabled={isLoading || !inputMessage.trim()}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'action.disabledBackground',
                                    color: 'action.disabled',
                                },
                                width: 40,
                                height: 40,
                            }}
                        >
                            <SendIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
}

export default AIChat; 